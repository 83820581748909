import { InconsistenciasRegistrosFaltantes } from '../types';
import { WebEntity } from './types';
import { specialFormatStringNumber } from '../utils/utils';

const InconsistenciasRegistrosFaltantesEntity: WebEntity<InconsistenciasRegistrosFaltantes> = {
  name: 'inconsistenciasRegistrosFaltantes',
  endpoint: 'inconsistenciasRegistrosFaltantes',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Item',
      selector: 'itemId',
      format: ({ itemId }) => specialFormatStringNumber(itemId, true, 0, 0),
      wrap: true
    },
    {
      name: 'Sku',
      selector: 'sku',
      wrap: true
    },
    {
      name: 'Descripcion',
      selector: 'descripcion',
      wrap: true
    },
    {
      name: 'Categoria',
      selector: 'categoria',
      wrap: true
    },
    {
      name: 'CL',
      selector: 'cl',
      wrap: true
    },
    {
      name: 'CD',
      selector: 'cd',
      wrap: true
    },
    {
      name: 'Producidos',
      selector: 'itemProduction',
      wrap: true
    },
    {
      name: 'Matriz Acarreo',
      selector: 'matrizAcarreo',
      wrap: true
    },
    {
      name: 'Cycle Time Distribucion',
      selector: 'cycleTimeDistribucion',
      wrap: true
    },
    {
      name: 'Matriz Produccion',
      selector: 'matrizProduccion',
      wrap: true
    },
    {
      name: 'Cycle Time Produccion',
      selector: 'cycleTimeProduccion',
      wrap: true
    }
  ],

  exportColumns: [
    {
      name: 'Item',
      selector: 'itemId',
      format: 'numeric',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Sku',
      selector: 'sku',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Descripcion',
      selector: 'descripcion',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Categoria',
      selector: 'categoria',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Cl',
      selector: 'cl',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'CD',
      selector: 'cd',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Producidos',
      selector: 'itemProduction',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Matriz Acarreo',
      selector: 'matrizAcarreo',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Cycle Time Distribucion',
      selector: 'cycleTimeDistribucion',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Matriz Produccion',
      selector: 'matrizProduccion',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Cycle Time Produccion',
      selector: 'cycleTimeProduccion',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    }
  ]
};

export default InconsistenciasRegistrosFaltantesEntity;
