import LandingPage from './LandingPage';
import NotFoundPage from './NotFoundPage';
import AdminPanelPage from './AdminPanelPage';
import WelcomePage from './WelcomePage';
import AnonimousPage from './AnonimousPage';
import ObserverPage from './ObserverPage';
import {
  ConstantesCRUDManteiner,
  ItemMaintainer,
  LocationCdCRUDManteiner,
  LocationClCRUDManteiner,
  LocationDictionaryCdCRUDManteiner,
  LocationDictionaryClCRUDManteiner,
  matrizAcarreoManteiner,
  matrizProduccionManteiner,
  PrecycleTimeDistribucionManteiner,
  PrecycleTimeProduccionManteiner,
  PrenivelServicioManteiner,
  TipoUsuarioCRUDMaintainer,
  UsuarioCRUDMaintainer
} from './Mantenedores';

/* MANTENEDOREES */

const Pages = {
  LandingPage,
  NotFoundPage,
  AdminPanelPage,
  WelcomePage,
  ObserverPage,
  AnonimousPage
};

export const Mantenedores = {
  UsuarioCRUDMaintainer,
  TipoUsuarioCRUDMaintainer,
  ItemMaintainer,
  matrizAcarreoManteiner,
  matrizProduccionManteiner,
  PrecycleTimeDistribucionManteiner,
  PrecycleTimeProduccionManteiner,
  PrenivelServicioManteiner,
  ConstantesCRUDManteiner,
  LocationCdCRUDManteiner,
  LocationDictionaryCdCRUDManteiner,
  LocationClCRUDManteiner,
  LocationDictionaryClCRUDManteiner
};

export default Pages;
