import axios from 'axios';
import {
  AUTH_ERROR,
  IMPERSONATE_CLEAR,
  IMPERSONATE_LOADED,
  IMPERSONATE_LOADING,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  USER_LOADED,
  USER_LOADING
} from '../reducers/types';
import { AppDispatch, AppGetState } from '../store';
import { User } from '../types';
import { jsonHeaders, tokenConfig } from '../utils/header';
import { removeFromStorage, TOKEN } from '../utils/storage';
import { returnErrors } from './errorActions';

/**
 * LoadUser Action
 *
 * Check token & Load user
 */
export const loadUser = () => async (dispatch: AppDispatch, getState: AppGetState) => {
  // User loading
  dispatch({ type: USER_LOADING });

  try {
    const user = (await axios.get<User>('/api/auth/user', tokenConfig(getState))).data;

    dispatch({
      type: USER_LOADED,
      payload: user
    });
  } catch (err) {
    if (!err || !(err as any).response) {
      removeFromStorage(TOKEN);
    }

    dispatch(returnErrors((err as any).response.data, (err as any).response.status));
    dispatch({
      type: AUTH_ERROR
    });
  }
};

/**
 * ImpersonateUser Action
 *
 * @param {String} targetEmail The email of the user to impersonate
 *
 * Get a valid token for the specified email without having to send email & password,
 * but only if the admin token describe an admin user
 */
export const impersonateUser =
  (targetEmail: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
    dispatch({ type: IMPERSONATE_LOADING });

    try {
      const { token, user } = (
        await axios.post<{ token: string; user: User }>(
          '/api/auth/personificar',
          { email: targetEmail },
          tokenConfig(getState)
        )
      ).data;

      dispatch({
        type: IMPERSONATE_LOADED,
        payload: {
          passwordLessToken: token,
          passwordLessUser: user
        }
      });
    } catch (err) {
      dispatch(returnErrors((err as any).response.data, (err as any).response.status));
    }
  };

/**
 * FinishImpersonation Action
 *
 * Clear current impersonation's data
 */
export const finishImpersonation = () => {
  return {
    type: IMPERSONATE_CLEAR
  };
};

/**
 * Register Action
 *
 * Register a new user
 */
export const register =
  ({ name, email, password }: { name: string; email: string; password: string }) =>
  async (dispatch: AppDispatch) => {
    const header = jsonHeaders();

    try {
      const { token, user } = (
        await axios.post<{ token: string; user: User }>(
          '/api/users/single',
          { nombreUsuario: name, emailUsuario: email, claveUsuario: password },
          header
        )
      ).data;

      dispatch({
        type: REGISTER_SUCCESS,
        payload: { token, user }
      });
    } catch (err) {
      dispatch(
        returnErrors((err as any).response.data, (err as any).response.status, REGISTER_FAIL)
      );
      dispatch({
        type: REGISTER_FAIL
      });
    }
  };

/**
 * Login Action
 *
 * Login user
 */
export const login =
  ({ userUsername, userPassword }: { userUsername: string; userPassword: string }) =>
  async (dispatch: AppDispatch) => {
    const header = jsonHeaders();

    try {
      const authenticatedUser = (
        await axios.post<{ token: string; user: User }>(
          '/api/auth',
          { userUsername, userPassword },
          header
        )
      ).data;

      dispatch({
        type: LOGIN_SUCCESS,
        payload: authenticatedUser
      });
      return LOGIN_SUCCESS;
    } catch (err) {
      dispatch(returnErrors((err as any).response.data, (err as any).response.status, LOGIN_FAIL));
      dispatch({
        type: LOGIN_FAIL
      });
      return LOGIN_FAIL;
    }
  };

/**
 * Logout Action
 *
 * Logout a User
 */
export const logout = () => {
  return {
    type: LOGOUT_SUCCESS
  };
};
