import { MatrizProduccion } from '../types';
import { WebEntity } from './types';

const MatrizProduccionEntity: WebEntity<MatrizProduccion> = {
  name: 'matrizProduccion',
  endpoint: 'matrizProduccion',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Categoria',
      selector: 'categoria',
      wrap: true
    },
    {
      name: 'SKU',
      selector: 'sku',
      wrap: true
    },
    {
      name: 'ARICA',
      selector: 'arica',
      wrap: true
    },
    {
      name: 'ANTOFAGASTA',
      selector: 'antofagasta',
      wrap: true
    },
    {
      name: 'IQQ HOSPICIO',
      selector: 'iqqHospicio',
      wrap: true
    },
    {
      name: 'CALAMA',
      selector: 'calama',
      wrap: true
    },
    {
      name: 'COPIAPO',
      selector: 'copiapo',
      wrap: true
    },
    {
      name: 'COQUIMBO',
      selector: 'coquimbo',
      wrap: true
    },
    {
      name: 'ILLAPEL',
      selector: 'illapel',
      wrap: true
    },
    {
      name: 'OVALLE TCCU',
      selector: 'ovalleTccu',
      wrap: true
    },
    {
      name: 'CURAUMA',
      selector: 'curauma',
      wrap: true
    },
    {
      name: 'LLAY LLAY',
      selector: 'llayLlay',
      wrap: true
    },
    {
      name: 'CD8000',
      selector: 'cd8000',
      wrap: true
    },
    {
      name: 'CERVECERA',
      selector: 'cervecera',
      wrap: true
    },
    {
      name: 'CD1500',
      selector: 'cd1500',
      wrap: true
    },
    {
      name: 'RENCA',
      selector: 'renca',
      wrap: true
    },
    {
      name: 'SANTIAGO SUR',
      selector: 'santiagoSur',
      wrap: true
    },
    {
      name: 'RGUA TCCU',
      selector: 'rguaTccu',
      wrap: true
    },
    {
      name: 'OLIVOS',
      selector: 'olivos',
      wrap: true
    },
    {
      name: 'TALCA',
      selector: 'talca',
      wrap: true
    },
    {
      name: 'TALCAHUANO',
      selector: 'talcahuano',
      wrap: true
    },
    {
      name: 'CHILLAN NUEVO',
      selector: 'chillanNuevo',
      wrap: true
    },
    {
      name: 'L.ANGELES NUEVO',
      selector: 'lAngelesNuevo',
      wrap: true
    },
    {
      name: 'TEMUCO',
      selector: 'temuco',
      wrap: true
    },
    {
      name: 'OSORNO',
      selector: 'osorno',
      wrap: true
    },
    {
      name: 'PUERTO MONTT',
      selector: 'puertoMontt',
      wrap: true
    },
    {
      name: 'CASTRO',
      selector: 'castro',
      wrap: true
    },
    {
      name: 'COYHAIQUE',
      selector: 'coyhaique',
      wrap: true
    },
    {
      name: 'PATAGONA',
      selector: 'patagona',
      wrap: true
    },
    {
      name: 'VALDIVIA TCCU',
      selector: 'valdiviaTccu',
      wrap: true
    }
  ],

  fields: [],

  editableFields: [],
  exportColumns: [
    {
      name: 'Categoria',
      selector: 'categoria',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'SKU',
      selector: 'sku',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'ARICA',
      selector: 'arica',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'ANTOFAGASTA',
      selector: 'antofagasta',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'IQQ HOSPICIO',
      selector: 'iqqHospicio',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'CALAMA',
      selector: 'calama',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'COPIAPO',
      selector: 'copiapo',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'COQUIMBO',
      selector: 'coquimbo',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'ILLAPEL',
      selector: 'illapel',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'OVALLE TCCU',
      selector: 'ovalleTccu',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'CURAUMA',
      selector: 'curauma',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'LLAY LLAY',
      selector: 'llayLlay',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'CD8000',
      selector: 'cd8000',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'CERVECERA',
      selector: 'cervecera',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'CD1500',
      selector: 'cd1500',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'RENCA',
      selector: 'renca',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'SANTIAGO SUR',
      selector: 'santiagoSur',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'RGUA TCCU',
      selector: 'rguaTccu',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'OLIVOS',
      selector: 'olivos',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'TALCA',
      selector: 'talca',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'TALCAHUANO',
      selector: 'talcahuano',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'CHILLAN NUEVO',
      selector: 'chillanNuevo',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'L.ANGELES NUEVO',
      selector: 'lAngelesNuevo',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'TEMUCO',
      selector: 'temuco',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'OSORNO',
      selector: 'osorno',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'PUERTO MONTT',
      selector: 'puertoMontt',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'CASTRO',
      selector: 'castro',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'COYHAIQUE',
      selector: 'coyhaique',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'PATAGONA',
      selector: 'patagona',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'VALDIVIA TCCU',
      selector: 'valdiviaTccu',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    }
  ]
};

// deafult 'key': {'name':'name','required':true,'export':true}
export const MA_PROD_COLUMNS = {
  categoria: 'Categoria',
  sku: 'SKU',
  arica: 'ARICA',
  antofagasta: 'ANTOFAGASTA',
  iqqHospicio: 'IQQ HOSPICIO',
  calama: 'CALAMA',
  copiapo: 'COPIAPO',
  coquimbo: 'COQUIMBO',
  illapel: 'ILLAPEL',
  ovalleTccu: 'OVALLE TCCU',
  curauma: 'CURAUMA',
  llayLlay: 'LLAY LLAY',
  cd8000: 'CD8000',
  cervecera: 'CERVECERA',
  cd1500: 'CD1500',
  renca: 'RENCA',
  santiagoSur: 'SANTIAGO SUR',
  rguaTccu: 'RGUA TCCU',
  olivos: 'OLIVOS',
  talca: 'TALCA',
  talcahuano: 'TALCAHUANO',
  chillanNuevo: 'CHILLAN NUEVO',
  lAngelesNuevo: 'L.ANGELES NUEVO',
  temuco: 'TEMUCO',
  osorno: 'OSORNO',
  puertoMontt: 'PUERTO MONTT',
  castro: 'CASTRO',
  coyhaique: 'COYHAIQUE',
  patagona: 'PATAGONA',
  valdiviaTccu: 'VALDIVIA TCCU'
};

export default MatrizProduccionEntity;
