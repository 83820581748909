export type SimpleObject = { [key: string]: any };
export type SimpleStringObject = { [key: string]: string };
export type CustomObject<T> = { [key: string]: T };

export enum ImageFormats {
  Png = 'png',
  Jpg = 'jpeg'
}

// TODO: Eliminar todo rastro del observer
export enum UserTypeValues {
  Operador = 'OPERADOR',
  Admin = 'ADMIN'
}

export interface BasicEntity {
  _id: string;
}

export interface PoliticasInventarioCdCl extends BasicEntity {
  politica: string;
  sku: string;
  descr: string;
  empaque: string;
  formato: string;
  categoria: string;
  cdCl: string;
  clOrigen: string;
  sistema: string;
  clasificacionAbcPais: string;
  clasificacionAbcCd: string;
  vuReal: number;
  vuSmkt: number;
  nivelServicio: number;
  distribucionNormal: number;
  demanda: number;
  forecast: number;
  errorAbsoluto: number;
  wmape: number;
  leadTimeCalculado: number;
  sdLeadTimeAlCuadrado: number;
  cycleTimeDistribucion: number;
  cycleTimeProduccion: number;
  sdSupply: number;
  safetyStockDias: number;
  reorderPointDias: number;
  inventarioPromedioDias: number;
  inventarioMaximoDias: number;
  userUsername: string;
  politicaDateCreated: string;
}

export enum OrderCreatedValues {
  LastUpdated = 1,
  HistoricalUpdated = 2
}

export interface InconsistenciasReporteCantidad extends BasicEntity {
  locationCdClName: string;
  itemCategoryName: string;
  forecast: number;
  venta: number;
  forecastVenta: number;
  variablesBaseCdStg: number;
  variablesBaseCd: number;
  politicasInventarioCd: number;
  forecastFutureStgCd: number;
  demandAverageCd: number;
  variablesBaseClStg: number;
  variablesBaseCl: number;
  politicasInventarioCl: number;
  forecastFutureStgCl: number;
  demandAverageCl: number;
  locationType: string;
}

export interface InconsistenciasRegistrosFaltantes extends BasicEntity {
  itemId: number;
  sku: string;
  descripcion: string;
  categoria: string;
  locationClName: string;
  itemProduction: string;
  matrizAcarreo: string;
  cycleTimeDistribucion: string;
  matrizProduccion: string;
  cycleTimeProduccion: string;
}

export interface InconsistenciasCategoria extends BasicEntity {
  categoria: string;
  dominio: string;
  cantidadRegistros: number;
}

export interface InconsistenciasCycleTimeProduccion extends BasicEntity {
  categoria: string;
  planta: string;
  sistema: string;
  sistemaDos: string;
  sku: string;
  abc: string;
  cycleTime: number;
  existeSkuEnMantenedor: string;
  existePlantaEnMantenedor: string;
  existeEnMatrizProduccion: string;
  tieneCycleTime: string;
}

export interface InconsistenciasMatrizAcarreo extends BasicEntity {
  sku: string;
  descripcion: string;
  idCdDestino: number;
  descrDepo: string;
}

export interface InconsistenciasRutasIncompletas extends BasicEntity {
  codCentroOrigen: number;
  descrSapOrigen: string;
  descrDepoOrigen: string;
  codCentroDestino: number;
  descrSapDestino: string;
  descrDepoDestino: string;
}

export interface InconsistenciasMatrizProduccion extends BasicEntity {
  origenMatriz: string;
  plntCodig: number;
  planta: string;
  cd: string;
  sku: number;
  existeSkuEnMantenedor: string;
  existeCdEnMantenedor: string;
  existePlantaEnMantenedor: string;
}

export interface InconsistenciasVariablesBaseCl extends BasicEntity {
  sku: string;
  idCd: number;
  descripcion: string;
  categoria: string;
  descrDepo: string;
  matrizProduccionPlanta: string;
  cycleTimeProduccionPlanta: string;
  existeEnMantenedorSku: string;
  existeEnMantenedorLocaciones: string;
  existeEnMatrizProduccion: string;
  existeCycleTimeProduccion: string;
}

export interface InconsistenciasVariablesBaseCd extends BasicEntity {
  sku: string;
  idCd: number;
  descripcion: string;
  categoria: string;
  descrDepo: string;
  existeEnMantenedorSku: string;
  poseeLeadTime: string;
  existeEnMantenedorLocaciones: string;
  poseeCycleTimeDistribucion: string;
  poseeNivelDeServicio: string;
}

// Mantenedores

export interface UserType extends BasicEntity {
  userTypeId: string;
  userTypeName: string;
}

export interface User extends BasicEntity {
  userId: string;
  userUsername: string;
  userEmail: string;
  userPassword: string;
  userFullname: string;
  userTypeId: string;
  userTypeName: string;
  userIsActive: string;
}

export interface Item extends BasicEntity {
  itemIdCcu: string;
  itemName: string;
  itemCategory: string;
  itemCategoryNew: string;
  itemFormat: string;
  itemPacking: string;
  shelfLife: number;
  factorHl: number;
}

export interface MatrizAcarreo extends BasicEntity {
  prioridad: string;
  lt: string;
  idCdDest: string;
  hasta: string;
  sku: string;
  destino: string;
  clave: string;
  origen: string;
  analista: string;
  desde: string;
}

export interface Categoria extends BasicEntity {
  categoriaId: string;
  categoriaDescripcion: string;
}

export interface Pais extends BasicEntity {
  paisId: string;
  paisDescripcion: string;
}

export interface Sistema extends BasicEntity {
  sistemaId: string;
  sistemaDescripcion: string;
}

export interface MatrizProduccion extends BasicEntity {
  categoria: string;
  sku: string;
  arica: string;
  antofagasta: string;
  iqqHospicio: string;
  calama: string;
  copiapo: string;
  coquimbo: string;
  illapel: string;
  ovalleTccu: string;
  curauma: string;
  llayLlay: string;
  cd8000: string;
  cervecera: string;
  cd1500: string;
  renca: string;
  santiagoSur: string;
  rguaTccu: string;
  olivos: string;
  talca: string;
  talcahuano: string;
  chillanNuevo: string;
  lAngelesNuevo: string;
  temuco: string;
  osorno: string;
  puertoMontt: string;
  castro: string;
  coyhaique: string;
  patagona: string;
  valdiviaTccu: string;
}

export interface CycleTimeProduccion extends BasicEntity {
  categoria: string;
  planta: string;
  sistema: string;
  sistemaDos: string;
  sku: string;
  abc: string;
  cycleTime: number;
}

export interface CycleTimeDistribucion extends BasicEntity {
  cd: string;
  descrGrupoProd: string;
  tipo: string;
  cycleTime: number;
  ruta: string;
  leadTimeAvg: number;
  diasLab: number;
}

export interface NivelServicio extends BasicEntity {
  categoria: string;
  abc: string;
  nivelServicio: number;
  distribucionNormal: number;
}

export interface Constantes extends BasicEntity {
  constantesId: string;
  nombre: string;
  descripcion: string;
  valorNumeric: string;
  valorInteger: string;
  valorText: string;
}

export enum ConstantesValueTypes {
  valorNumeric = 'valorNumeric',
  valorInteger = 'valorInteger',
  valorText = 'valorText'
}

export interface LocationCd extends BasicEntity {
  locationCdId: string;
  locationCdName: string;
}

export interface LocationDictionaryCd extends BasicEntity {
  locationDictionaryId: string;
  locationDictionaryNameSource: string;
  locationCdId: string;
  locationCdName: string;
}

export interface LocationCl extends BasicEntity {
  locationClId: string;
  locationClName: string;
}

export interface LocationDictionaryCl extends BasicEntity {
  locationDictionaryClId: string;
  locationDictionaryClNameSource: string;
  locationClId: string;
  locationClName: string;
}
