import { InconsistenciasMatrizAcarreo } from '../types';
import { WebEntity } from './types';

const InconsistenciasMatrizAcarreoEntity: WebEntity<InconsistenciasMatrizAcarreo> = {
  name: 'inconsistenciasMatrizAcarreo',
  endpoint: 'inconsistenciasMatrizAcarreo',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Sku',
      selector: 'sku'
    },
    {
      name: 'Descripcion',
      selector: 'descripcion'
    },
    {
      name: 'Id Cd Destino',
      selector: 'idCdDestino'
    },
    {
      name: 'Descr Depo',
      selector: 'descrDepo'
    }
  ],

  fields: [],
  editableFields: [],
  filterFields: [],
  exportColumns: [
    {
      name: 'Sku',
      selector: 'sku',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Descripcion',
      selector: 'descripcion',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Id Cd Destino',
      selector: 'idCdDestino',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Descr Depo',
      selector: 'descrDepo',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    }
  ]
};

export default InconsistenciasMatrizAcarreoEntity;
