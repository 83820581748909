import { LocationCl } from '../types';
import { FieldTypes, WebEntity } from './types';

const LocationClEntity: WebEntity<LocationCl> = {
  name: 'locationCl',
  endpoint: 'locationCl',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'ID CL en Politicas de Inventario',
      selector: 'locationClId',
      wrap: true
    },
    {
      name: 'Nombre CL en Politicas de Inventario',
      selector: 'locationClName',
      wrap: true
    }
  ],

  fields: [
    {
      name: 'Nombre CL en Politicas de Inventario',
      selector: 'locationClName',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: [
    {
      name: 'Nombre CL en Politicas de Inventario',
      selector: 'locationClName',
      type: FieldTypes.Text,
      required: true
    }
  ],

  exportColumns: [
    {
      name: 'ID CL en Politicas de Inventario',
      selector: 'locationClId',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Nombre CL en Politicas de Inventario',
      selector: 'locationClName',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    }
  ]
};

export default LocationClEntity;
