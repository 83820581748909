import { Pais } from '../types';
import { WebEntity } from './types';

const PaisEntity: WebEntity<Pais> = {
  name: 'pais',
  endpoint: 'pais',
  referenceColumn: 'paisId',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Pais Id',
      selector: 'paisId',
      sortable: true
    },
    {
      name: 'Pais Descripcion',
      selector: 'paisDescripcion',
      sortable: true
    }
  ],

  fields: [],

  editableFields: []
};

export default PaisEntity;
