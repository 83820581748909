import { InconsistenciasCategoria } from '../types';
import { WebEntity } from './types';
import { specialFormatStringNumber } from '../utils/utils';

const InconsistenciasCategoriaEntity: WebEntity<InconsistenciasCategoria> = {
  name: 'inconsistenciasCategoria',
  endpoint: 'inconsistenciasCategoria',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Categoria',
      selector: 'categoria'
    },
    {
      name: 'Dominio',
      selector: 'dominio'
    },
    {
      name: 'Cantidad Registros',
      selector: 'cantidadRegistros',
      format: ({ cantidadRegistros }) => specialFormatStringNumber(cantidadRegistros, false, 0, 0)
    }
  ],

  fields: [],
  editableFields: [],
  filterFields: [],
  exportColumns: [
    {
      name: 'Categoria',
      selector: 'categoria',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Dominio',
      selector: 'dominio',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Cantidad Registros',
      selector: 'cantidadRegistros',
      format: 'numeric',
      decimalSeparator: ',',
      thousandSeparator: '.'
    }
  ]
};

export default InconsistenciasCategoriaEntity;
