import { MatrizAcarreo } from '../types';
import { WebEntity } from './types';

const MatrizAcarreoEntity: WebEntity<MatrizAcarreo> = {
  name: 'matrizAcarreo',
  endpoint: 'matrizAcarreo',
  referenceColumn: 'sku',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Prioridad',
      selector: 'prioridad',
      sortable: true
    },
    {
      name: 'Lt',
      selector: 'lt'
    },
    {
      name: 'Id Cd Dest',
      selector: 'idCdDest'
    },
    {
      name: 'Hasta',
      selector: 'hasta'
    },
    {
      name: 'Sku',
      selector: 'sku'
    },
    {
      name: 'Destino',
      selector: 'destino',
      wrap: true
    },
    {
      name: 'Clave',
      selector: 'clave',
      wrap: true
    },
    {
      name: 'Origen',
      selector: 'origen'
    },
    {
      name: 'Analista',
      selector: 'analista',
      wrap: true
    },
    {
      name: 'Desde',
      selector: 'desde'
    }
  ],

  fields: [],

  editableFields: [],

  exportColumns: [
    {
      name: 'Prioridad',
      selector: 'prioridad',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Lt',
      selector: 'lt',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Id Cd Dest',
      selector: 'idCdDest',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Hasta',
      selector: 'hasta',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Sku',
      selector: 'sku',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Destino',
      selector: 'destino',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Clave',
      selector: 'clave',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Origen',
      selector: 'origen',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Analista',
      selector: 'analista',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Desde',
      selector: 'desde',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    }
  ]
};

// deafult 'key': {'name':'name','required':true,'export':true}
export const MA_ACARR_COLUMNS = {
  prioridad: 'Prioridad',
  lt: 'Lt',
  idCdDest: 'Id Cd Dest',
  hasta: 'Hasta',
  sku: 'Sku',
  destino: 'Destino',
  clave: 'Clave',
  origen: 'Origen',
  analista: 'Analista',
  desde: 'Desde'
};

export default MatrizAcarreoEntity;
