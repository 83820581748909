import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../store';
import { AuthState, EntitiesState } from '../reducers/types';
import { ReporteCDCL } from '../components/GeneratedComponents';
import { RefreshReporteCdClButton } from '../components/SecondaryButton';
import { OrderCreatedValues, UserTypeValues } from '../types';
import { dateFormatFn } from '../utils/utils';
import { getUncatchEndpointData } from '../utils/request';
import { requestErrorPopAlert } from '../components/PopAlert';
import * as E from '../entities';
import AsyncExportXLSX from '../components/AsyncExportXLSX';
import { ExportColumns } from '../entities/types';
import '../css/politicaCdClPage.css';

const columnsDRP: Array<ExportColumns> = [
  {
    name: 'Semana',
    selector: 'semana',
    format: 'dateUTC'
  },
  {
    name: 'Sku',
    selector: 'sku',
    format: 'text'
  },
  {
    name: 'cd',
    selector: 'cd',
    format: 'text'
  },
  {
    name: 'Reorder Point',
    selector: 'reorderPoint',
    format: 'numeric'
  },
  {
    name: 'Safe Stock',
    selector: 'safeStock',
    format: 'numeric'
  },
  {
    name: 'Max Stock',
    selector: 'maxStock',
    format: 'numeric'
  }
];

interface IProps {
  auth: AuthState;
  entities: EntitiesState;
}

const PoliticaCdClPage: FunctionComponent<IProps> = ({ auth, entities }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [existingHistoricalData, setExistingHistoricalData] = useState<boolean>(false);
  const [historialUserName, setHistoricalUserName] = useState<string>();
  const [historicalDate, setHistoricalDate] = useState<string>();
  const [lastUpdatedUserName, setLastUpdatedUserName] = useState<string>();
  const [lastUpdatedDate, setLastUpdatedDate] = useState<string>();

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const res = await getUncatchEndpointData({
          endpoint: 'politicasInventarioCdCl/historicalAuditData'
        });
        if (res && res.length > 0) {
          setHistoricalUserName(res[0].userUsername);
          setHistoricalDate(res[0].politicaDateCreated);
          setExistingHistoricalData(true);
        } else {
          setExistingHistoricalData(false);
        }
      } catch (error) {
        console.log(error);
        requestErrorPopAlert(error);
      }
      setLoading(false);
    };
    getData();
    if (entities.politicasInventarioCdCl.list.length > 0) {
      setLastUpdatedDate(entities.politicasInventarioCdCl.list[0].politicaDateCreated);
      setLastUpdatedUserName(entities.politicasInventarioCdCl.list[0].userUsername);
    }
  }, [entities.politicasInventarioCdCl.list]);

  return (
    <div>
      <div className='admin-panel-container'>
        <div className='button-data-container'>
          <AsyncExportXLSX
            endpoint='politicasInventarioCdCl/politicasDRP'
            COLUMNS={columnsDRP}
            buttonName='Exportar DRP'
            fileName='Politicas-DRP'
          />
        </div>
        <div className='button-data-container'>
          <div className='text'>
            <h2 className='text-desc'>Histórico:</h2>
            <p className='text-desc-value'>
              {loading ? (
                'Cargando...'
              ) : !existingHistoricalData ? (
                'No existen registros históricos'
              ) : (
                <>
                  {historicalDate && dateFormatFn(historicalDate, 'DD/MM/YYYY HH:mm:ss', false)} por{' '}
                  <span className='text-desc-value-user'>{historialUserName}</span>
                </>
              )}
            </p>
          </div>
          <AsyncExportXLSX
            endpoint={`politicasInventarioCdCl/export/${OrderCreatedValues.HistoricalUpdated}`}
            COLUMNS={E.PoliticasInventarioCdClEntity.exportColumns!}
            buttonName='Exportar Historico'
            fileName='reporteCdCl-historico'
          />
        </div>
        <div className='button-data-container'>
          <div className='text'>
            <h2 className='text-desc'>Última actualización:</h2>
            <p className='text-desc-value'>
              {lastUpdatedDate && dateFormatFn(lastUpdatedDate, 'DD/MM/YYYY HH:mm:ss', false)} por{' '}
              <span className='text-desc-value-user'>{lastUpdatedUserName}</span>
            </p>
          </div>
          {auth.user?.userTypeName === UserTypeValues.Admin && <RefreshReporteCdClButton />}
        </div>
      </div>
      <ReporteCDCL />
    </div>
  );
};

export default connect(({ auth, entities }: AppState) => ({ auth, entities }))(PoliticaCdClPage);
