import { InconsistenciasVariablesBaseCl } from '../types';
import { WebEntity } from './types';

const InconsistenciasVariablesBaseClEntity: WebEntity<InconsistenciasVariablesBaseCl> = {
  name: 'inconsistenciasVariablesBaseCl',
  endpoint: 'inconsistenciasVariablesBaseCl',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Sku',
      selector: 'sku'
    },
    {
      name: 'Id Cd',
      selector: 'idCd'
    },
    {
      name: 'Descripcion',
      selector: 'descripcion',
      wrap: true
    },
    {
      name: 'Categoria',
      selector: 'categoria'
    },
    {
      name: 'Descr Depo',
      selector: 'descrDepo',
      wrap: true
    },
    {
      name: 'Matriz Produccion Planta',
      selector: 'matrizProduccionPlanta',
      wrap: true
    },
    {
      name: 'Cycle Time Produccion Planta',
      selector: 'cycleTimeProduccionPlanta'
    },
    {
      name: 'Existe En Mantenedor Sku',
      selector: 'existeEnMantenedorSku'
    },
    {
      name: 'Existe En Mantenedor Locaciones',
      selector: 'existeEnMantenedorLocaciones'
    },
    {
      name: 'Existe En Matriz Produccion',
      selector: 'existeEnMatrizProduccion'
    },
    {
      name: 'Existe Cycle Time Produccion',
      selector: 'existeCycleTimeProduccion'
    }
  ],

  fields: [],
  editableFields: [],
  filterFields: [],
  exportColumns: [
    {
      name: 'Sku',
      selector: 'sku',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Id Cd',
      selector: 'idCd',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Descripcion',
      selector: 'descripcion',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Categoria',
      selector: 'categoria',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Descr Depo',
      selector: 'descrDepo',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Matriz Produccion Planta',
      selector: 'matrizProduccionPlanta',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Cycle Time Produccion Planta',
      selector: 'cycleTimeProduccionPlanta',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Existe En Mantenedor Sku',
      selector: 'existeEnMantenedorSku',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Existe En Mantenedor Locaciones',
      selector: 'existeEnMantenedorLocaciones',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Existe En Matriz Produccion',
      selector: 'existeEnMatrizProduccion',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Existe Cycle Time Produccion',
      selector: 'existeCycleTimeProduccion',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    }
  ]
};

export default InconsistenciasVariablesBaseClEntity;
