import { User } from '../types';
import { FieldTypes, WebEntity } from './types';
import { booleanFormatFn } from '../utils/utils';

const UserEntity: WebEntity<User> = {
  name: 'user',
  endpoint: 'users',
  referenceColumn: 'userUsername', //TODO: make it multicolumn, the utils should have the 'not found' text as optional param

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Nombre Usuario',
      selector: 'userUsername',
      sortable: true
    },
    {
      name: 'Nombre',
      selector: 'userFullname',
      wrap: true,
      sortable: true
    },
    {
      name: 'Email',
      selector: 'userEmail',
      wrap: true,
      sortable: true
    },
    {
      name: 'Tipo',
      selector: 'userTypeName',
      sortable: true
    },
    {
      name: '¿Activo?',
      selector: 'activo',
      format: ({ userIsActive }) => booleanFormatFn(userIsActive),
      sortable: true
    }
  ],

  fields: [
    {
      name: 'Nombre',
      selector: 'userFullname',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Nombre de Usuario para login',
      selector: 'userUsername',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Email',
      selector: 'userEmail',
      type: FieldTypes.Email,
      required: true
    },
    {
      name: 'Clave',
      selector: 'userPassword',
      type: FieldTypes.Password,
      required: true
    },
    {
      name: 'Tipo',
      selector: 'userTypeId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'userType',
        endpoint: 'userTypes',
        select: '_id',
        show: 'userTypeName',
        filter: {
          activo: true
        }
      }
    }
  ],

  editableFields: [
    {
      name: 'Nombre',
      selector: 'userFullname',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Nombre de Usuario para login',
      selector: 'userUsername',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Clave',
      selector: 'userPassword',
      type: FieldTypes.Password
    },
    {
      name: 'Email',
      selector: 'userEmail',
      type: FieldTypes.Email
    },
    {
      name: '¿Activo?',
      selector: 'userIsActive',
      type: FieldTypes.Boolean
    },
    {
      name: 'Tipo',
      selector: 'userTypeId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'userType',
        endpoint: 'userTypes',
        select: '_id',
        show: 'userTypeName',
        filter: {
          activo: true
        }
      }
    }
  ]
};

export default UserEntity;
