import * as E from '../entities';
import { generateCRUD } from './CRUDGenerator';
import { OrderCreatedValues } from '../types';

// GENERIC COMPONENTS ============================================

// REPORTES ============================================

export const ReporteCDCL = generateCRUD({
  webEntity: E.PoliticasInventarioCdClEntity,
  title: 'Reporte CD CL',
  query: { orderCreated: OrderCreatedValues.LastUpdated },
  serverSidePagination: true,
  sharedFilterName: 'SharedFilterPoliticasCdCl',
  customExport: {
    endpoint: `politicasInventarioCdCl/export/${OrderCreatedValues.LastUpdated}`,
    COLUMNS: E.PoliticasInventarioCdClEntity.exportColumns!,
    buttonName: 'Exportar',
    fileName: 'reporteCdCl'
  },
  allowedActions: {
    export: true,
    delete: false,
    edit: false,
    add: false,
    select: { enable: false }
  }
});

// INCONSISTENCIAS ============================================

export const InconsistenciaReporteCantidad = generateCRUD({
  webEntity: E.InconsistenciasReporteCantidadEntity,
  title: 'Inconsistencias Reporte Cantidad',
  allowedActions: {
    export: true,
    delete: false,
    edit: false,
    add: false,
    select: { enable: false }
  }
});

export const InconsistenciaRegistrosFaltantes = generateCRUD({
  webEntity: E.InconsistenciasRegistrosFaltantesEntity,
  title: 'Inconsistencias Registros Faltantes',
  allowedActions: {
    export: true,
    delete: false,
    edit: false,
    add: false,
    select: { enable: false }
  }
});
export const InconsistenciaCategoria = generateCRUD({
  webEntity: E.InconsistenciasCategoriaEntity,
  title: 'Inconsistencias Categoria',
  allowedActions: {
    export: true,
    delete: false,
    edit: false,
    add: false,
    select: { enable: false }
  }
});

export const InconsistenciaCycleTimeProduccion = generateCRUD({
  webEntity: E.InconsistenciasCycleTimeProduccionEntity,
  title: 'Inconsistencias Cycle Time Produccion',
  allowedActions: {
    export: true,
    delete: false,
    edit: false,
    add: false,
    select: { enable: false }
  }
});

export const InconsistenciaMatrizAcarreo = generateCRUD({
  webEntity: E.InconsistenciasMatrizAcarreoEntity,
  title: 'Inconsistencias Matriz Acarreo',
  allowedActions: {
    export: true,
    delete: false,
    edit: false,
    add: false,
    select: { enable: false }
  }
});

export const InconsistenciaRutasIncompletas = generateCRUD({
  webEntity: E.InconsistenciasRutasIncompletasEntity,
  title: 'Inconsistencias Rutas Incompletas',
  allowedActions: {
    export: true,
    delete: false,
    edit: false,
    add: false,
    select: { enable: false }
  }
});

export const InconsistenciaMatrizProduccion = generateCRUD({
  webEntity: E.InconsistenciasMatrizProduccionEntity,
  title: 'Inconsistencias Matriz Produccion',
  allowedActions: {
    export: true,
    delete: false,
    edit: false,
    add: false,
    select: { enable: false }
  }
});

export const InconsistenciaVariablesBaseCl = generateCRUD({
  webEntity: E.InconsistenciasVariablesBaseClEntity,
  title: 'Inconsistencias Variable Base Cl',
  allowedActions: {
    export: true,
    delete: false,
    edit: false,
    add: false,
    select: { enable: false }
  }
});

export const InconsistenciaVariablesBaseCd = generateCRUD({
  webEntity: E.InconsistenciasVariablesBaseCdEntity,
  title: 'Inconsistencias Variable Base CD',
  allowedActions: {
    export: true,
    delete: false,
    edit: false,
    add: false,
    select: { enable: false }
  }
});

// MANTENEDORES ============================================
export const UsuarioCRUD = generateCRUD({
  webEntity: E.UserEntity,
  title: 'Administracion Usuario',
  allowedActions: { export: true, delete: false, edit: true, select: { enable: false } }
});

export const TipoUsuarioCRUD = generateCRUD({
  webEntity: E.UserTypeEntity,
  title: 'Administracion Tipo Usuario',
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  }
});

export const ItemCRUD = generateCRUD({
  webEntity: E.ItemEntity,
  title: 'Administracion Sku',
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  }
});

export const MatrizAcarreoCRUD = generateCRUD({
  webEntity: E.MatrizAcarreoEntity,
  title: 'Administracion Matriz Acarreo',
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  }
});

export const CategoriaCRUD = generateCRUD({
  webEntity: E.CategoriaEntity,
  title: 'Administracion Categoria',
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  }
});

export const PaisCRUD = generateCRUD({
  webEntity: E.PaisEntity,
  title: 'Administracion Pais',
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  }
});

export const SistemaCRUD = generateCRUD({
  webEntity: E.SistemaEntity,
  title: 'Administracion Sistema',
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  }
});

export const MatrizProduccionCRUD = generateCRUD({
  webEntity: E.MatrizProduccionEntity,
  title: 'Administracion Matriz Produccion',
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  }
});

export const CycleTimeProduccionCRUD = generateCRUD({
  webEntity: E.CycleTimeProduccionEntity,
  title: 'Administracion Cycle Time Produccion',
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  }
});

export const CycleTimeDistribucionCRUD = generateCRUD({
  webEntity: E.CycleTimeDistribucionEntity,
  title: 'Administracion Cycle Time Distribucion',
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  }
});

export const NivelServicioCRUD = generateCRUD({
  webEntity: E.NivelServicioEntity,
  title: 'Administracion Nivel Servicio',
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  }
});

export const ConstantesCRUD = generateCRUD({
  webEntity: E.ConstantesEntity,
  title: 'Administracion Constantes',
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: true,
    select: { enable: false }
  }
});

export const LocationCdCRUD = generateCRUD({
  webEntity: E.LocationCdEntity,
  title: 'Administracion Locaciones CD',
  allowedActions: {
    export: true,
    add: true,
    delete: true,
    edit: true,
    select: { enable: true }
  }
});

export const LocationDictionaryCdCRUD = generateCRUD({
  webEntity: E.LocationDictionaryCdEntity,
  title: 'Administracion Diccionario Locaciones CD',
  allowedActions: {
    export: true,
    add: true,
    delete: true,
    edit: true,
    select: { enable: true }
  }
});

export const LocationClCRUD = generateCRUD({
  webEntity: E.LocationClEntity,
  title: 'Administracion Locaciones CL',
  allowedActions: {
    export: true,
    add: true,
    delete: true,
    edit: true,
    select: { enable: true }
  }
});

export const LocationDictionaryClCRUD = generateCRUD({
  webEntity: E.LocationDictionaryClEntity,
  title: 'Administracion Diccionario Locaciones CL',
  allowedActions: {
    export: true,
    add: true,
    delete: true,
    edit: true,
    select: { enable: true }
  }
});
