import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../store';
import FileReaderCSV, { FileReaderColumnProps } from '../components/FileReader';
import { errorPopAlert } from '../components/PopAlert';
import {
  itemMassiveLoad,
  matrizProduccionMassiveLoad,
  matrizAcarreoMassiveLoad,
  cycleTimeDistribucionMassiveLoad,
  cycleTimeProduccionMassiveLoad,
  nivelServicioMassiveLoad
} from '../actions/middleActions';
import {
  ItemCRUD,
  MatrizProduccionCRUD,
  MatrizAcarreoCRUD,
  CycleTimeDistribucionCRUD,
  CycleTimeProduccionCRUD,
  NivelServicioCRUD,
  ConstantesCRUD,
  UsuarioCRUD,
  TipoUsuarioCRUD,
  LocationCdCRUD,
  LocationDictionaryCdCRUD,
  LocationClCRUD,
  LocationDictionaryClCRUD
} from '../components/GeneratedComponents';
import { ITEM_COLUMNS } from '../entities/ItemEntity';
import { MA_PROD_COLUMNS } from '../entities/MatrizProduccionEntity';
import { MA_ACARR_COLUMNS } from '../entities/MatrizAcarreoEntity';
import { CY_TIME_DIS_COLUMNS } from '../entities/CycleTimeDistribucionEntity';
import { CY_TIME_PRD_COLUMNS } from '../entities/CycleTimeProduccionEntity';
import { NIV_SER_COLUMNS } from '../entities/NivelServicioEntity';
import GenericModal from '../components/generics/GenericModal';
import { CCUThemeButtonCargarInventario } from '../components/generics/GenericThemes';
import GenericInfoTag, { InfoTagSizeValues } from '../components/generics/GenericInfoTag';

export type SpecialMaintainerComponentProps = {
  fileReaderCallback: (data: Array<any>) => Promise<any>;
  fileReaderColumns: FileReaderColumnProps;
  componentToRender: JSX.Element;
  buttonName?: string;
  size?: string;
  title?: string;
};

export const SpecialMaintainersComponent: FunctionComponent<SpecialMaintainerComponentProps> = ({
  fileReaderCallback,
  fileReaderColumns,
  componentToRender,
  buttonName,
  size,
  title
}) => {
  const uploadFile = async (data: any) => {
    if (!data) {
      errorPopAlert(`Debes cargar un documento`);
      return;
    }

    const res = await fileReaderCallback(data);

    if (res && res.status === 200) {
      alert(`Se actualizaron los datos exitosamente!`);
    }
  };

  return (
    <div style={{ display: 'flex', gap: 20, flexDirection: 'column', margin: 25 }}>
      <div>
        <GenericInfoTag
          icon='info'
          title={title}
          strongTitle='Importante!'
          typeInfo={
            size === InfoTagSizeValues.notbg ? InfoTagSizeValues.notbg : InfoTagSizeValues.bg //default BIG
          }
        />
        <GenericModal
          title='Carga de Inventario'
          style={{ ...CCUThemeButtonCargarInventario }}
          buttonName={buttonName ? buttonName : 'PROCESAR ARCHIVO'}
          icon='update'
          localIcon={true}>
          <FileReaderCSV columns={fileReaderColumns} callback={uploadFile} />
        </GenericModal>
      </div>
      {componentToRender}
    </div>
  );
};

const generateConnectedSpecialMaintainersComponent = (fileReaderCallback: any) =>
  connect(({ auth }: AppState) => ({ auth }), {
    fileReaderCallback
  })(SpecialMaintainersComponent);

const PreItemMaintainer = generateConnectedSpecialMaintainersComponent(itemMassiveLoad);
const PrematrizProduccionMassive = generateConnectedSpecialMaintainersComponent(
  matrizProduccionMassiveLoad
);
const PrematrizAcarreoMassive =
  generateConnectedSpecialMaintainersComponent(matrizAcarreoMassiveLoad);
const PrecycleTimeDistribucionMassive = generateConnectedSpecialMaintainersComponent(
  cycleTimeDistribucionMassiveLoad
);
const PrecycleTimeProduccionMassive = generateConnectedSpecialMaintainersComponent(
  cycleTimeProduccionMassiveLoad
);
const PrenivelServicioMassive =
  generateConnectedSpecialMaintainersComponent(nivelServicioMassiveLoad);

export const UsuarioCRUDMaintainer = () => <UsuarioCRUD />;

export const TipoUsuarioCRUDMaintainer = () => <TipoUsuarioCRUD />;

export const ItemMaintainer = () => (
  <PreItemMaintainer
    {...{
      fileReaderColumns: ITEM_COLUMNS,
      componentToRender: <ItemCRUD />,
      buttonName: 'AGREGAR SKU',
      size: InfoTagSizeValues.bg,
      title:
        'Al subir un archivo, se actualizará la información de los datos existentes y se añadirá la información adicional.'
    }}
  />
);

export const matrizAcarreoManteiner = () => (
  <PrematrizAcarreoMassive
    {...{
      fileReaderColumns: MA_ACARR_COLUMNS,
      componentToRender: <MatrizAcarreoCRUD />,
      buttonName: 'AGREGAR MATRIZ ACARREO',
      size: InfoTagSizeValues.bg,
      title:
        'Al subir un archivo, se eliminará la información de los datos existentes y se reemplazará por nueva la información.'
    }}
  />
);

export const matrizProduccionManteiner = () => (
  <PrematrizProduccionMassive
    {...{
      fileReaderColumns: MA_PROD_COLUMNS,
      componentToRender: <MatrizProduccionCRUD />,
      buttonName: 'AGREGAR MATRIZ PRODUCCION',
      size: InfoTagSizeValues.bg,
      title:
        'Al subir un archivo, se eliminará la información de los datos existentes y se reemplazará por nueva la información.'
    }}
  />
);

export const PrecycleTimeDistribucionManteiner = () => (
  <PrecycleTimeDistribucionMassive
    {...{
      fileReaderColumns: CY_TIME_DIS_COLUMNS,
      componentToRender: <CycleTimeDistribucionCRUD />,
      buttonName: 'AGREGAR CYCLE TIME DISTRIBUCION',
      size: InfoTagSizeValues.bg,
      title:
        'Al subir un archivo, se eliminará la información de los datos existentes y se reemplazará por nueva la información.'
    }}
  />
);

export const PrecycleTimeProduccionManteiner = () => (
  <PrecycleTimeProduccionMassive
    {...{
      fileReaderColumns: CY_TIME_PRD_COLUMNS,
      componentToRender: <CycleTimeProduccionCRUD />,
      buttonName: 'AGREGAR CYCLE TIME PRODUCCION',
      size: InfoTagSizeValues.bg,
      title:
        'Al subir un archivo, se eliminará la información de los datos existentes y se reemplazará por nueva la información.'
    }}
  />
);

export const PrenivelServicioManteiner = () => (
  <PrenivelServicioMassive
    {...{
      fileReaderColumns: NIV_SER_COLUMNS,
      componentToRender: <NivelServicioCRUD />,
      buttonName: 'AGREGAR NIVEL SERVICIOS',
      size: InfoTagSizeValues.bg,
      title:
        'Al subir un archivo, se eliminará la información de los datos existentes y se reemplazará por nueva la información.'
    }}
  />
);

export const ConstantesCRUDManteiner: FunctionComponent = () => <ConstantesCRUD />;

export const LocationCdCRUDManteiner: FunctionComponent = () => <LocationCdCRUD />;

export const LocationDictionaryCdCRUDManteiner: FunctionComponent = () => (
  <LocationDictionaryCdCRUD />
);

export const LocationClCRUDManteiner: FunctionComponent = () => <LocationClCRUD />;

export const LocationDictionaryClCRUDManteiner: FunctionComponent = () => (
  <LocationDictionaryClCRUD />
);
