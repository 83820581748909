import { PoliticasInventarioCdCl } from '../types';
import { WebEntity, TableColumn, FieldTypes } from './types';
import { specialFormatStringNumber } from '../utils/utils';

const monthNames = [
  'One',
  'Two',
  'Three',
  'Four',
  'Five',
  'Six',
  'Seven',
  'Eight',
  'Nine',
  'Ten',
  'Eleven',
  'Twelve',
  'Thirteen',
  'Fourteen',
  'Fifteen',
  'Sixteen',
  'Seventeen',
  'Eighteen'
];
const spanishMonthNames = [
  'Uno',
  'Dos',
  'Tres',
  'Cuatro',
  'Cinco',
  'Seis',
  'Siete',
  'Ocho',
  'Nueve',
  'Diez',
  'Once',
  'Doce',
  'Trece',
  'Catorce',
  'Quince',
  'Dieciséis',
  'Diecisiete',
  'Dieciocho'
];
const tableColumnsAcumulated = [];
const exportColumnsAcumulated = [];

type Data = {
  demandAverageMonth: number;
  safeStockBoxMonth: number;
  reorderPointBoxMonth: number;
  averageInventoryBoxMonth: number;
  maxInventoryBoxMonth: number;
};

for (let i = 0; i < monthNames.length; i++) {
  const monthName = monthNames[i];
  const spanishMonthName = spanishMonthNames[i];

  const tableColumns: TableColumn<Data>[] = [
    {
      name: `Demanda Promedio Mes ${spanishMonthName}`,
      selector: `demandAverageMonth${monthName}`,
      format: (data: Data) =>
        specialFormatStringNumber(data[`demandAverageMonth${monthName}` as keyof Data], true, 0, 2),
      wrap: true
    },
    {
      name: `Safety Stock Cajas Mes ${spanishMonthName}`,
      selector: `safetyStockBoxMonth${monthName}`,
      format: (data: Data) =>
        specialFormatStringNumber(
          data[`safetyStockBoxMonth${monthName}` as keyof Data],
          true,
          0,
          0
        ),
      wrap: true
    },
    {
      name: `Reorder Point Cajas Mes ${spanishMonthName}`,
      selector: `reorderPointBoxMonth${monthName}`,
      format: (data: Data) =>
        specialFormatStringNumber(
          data[`reorderPointBoxMonth${monthName}` as keyof Data],
          true,
          0,
          0
        ),
      wrap: true
    },
    {
      name: `Inventario Promedio Cajas Mes ${spanishMonthName}`,
      selector: `averageInventoryBoxMonth${monthName}`,
      format: (data: Data) =>
        specialFormatStringNumber(
          data[`averageInventoryBoxMonth${monthName}` as keyof Data],
          true,
          0,
          0
        ),
      wrap: true
    },
    {
      name: `Inventario Maximo Cajas Mes ${spanishMonthName}`,
      selector: `maxInventoryBoxMonth${monthName}`,
      format: (data: Data) =>
        specialFormatStringNumber(
          data[`maxInventoryBoxMonth${monthName}` as keyof Data],
          true,
          0,
          0
        ),
      wrap: true
    }
  ];

  tableColumnsAcumulated.push(...tableColumns);

  const exportColumns = [
    {
      name: `Demanda Promedio Mes ${spanishMonthName}`,
      selector: `demandAverageMonth${monthName}`,
      format: 'numeric'
    },
    {
      name: `Safety Stock Cajas Mes ${spanishMonthName}`,
      selector: `safetyStockBoxMonth${monthName}`,
      format: 'numeric'
    },
    {
      name: `Reorder Point Cajas Mes ${spanishMonthName}`,
      selector: `reorderPointBoxMonth${monthName}`,
      format: 'numeric'
    },
    {
      name: `Inventario Promedio Cajas Mes ${spanishMonthName}`,
      selector: `averageInventoryBoxMonth${monthName}`,
      format: 'numeric'
    },
    {
      name: `Inventario Maximo Cajas Mes ${spanishMonthName}`,
      selector: `maxInventoryBoxMonth${monthName}`,
      format: 'numeric'
    }
  ];

  exportColumnsAcumulated.push(...exportColumns);
}

const PoliticasInventarioCdClEntity: WebEntity<PoliticasInventarioCdCl> = {
  name: 'politicasInventarioCdCl',
  endpoint: 'politicasInventarioCdCl',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Politica',
      selector: 'politica'
    },
    {
      name: 'Sku',
      selector: 'sku'
    },
    {
      name: 'Descr',
      selector: 'descr',
      wrap: true
    },
    {
      name: 'Empaque',
      selector: 'empaque'
    },
    {
      name: 'Formato',
      selector: 'formato',
      wrap: true
    },
    {
      name: 'Categoria',
      selector: 'categoria',
      wrap: true
    },
    {
      name: 'Cd Cl',
      selector: 'cdCl',
      wrap: true
    },
    {
      name: 'Cl Origen',
      selector: 'clOrigen',
      wrap: true
    },
    {
      name: 'Sistema',
      selector: 'sistema',
      wrap: true
    },
    {
      name: 'Clasificacion Abc Pais',
      selector: 'clasificacionAbcPais'
    },
    {
      name: 'Clasificacion Abc Cd',
      selector: 'clasificacionAbcCd'
    },
    {
      name: 'Vu Real',
      selector: 'vuReal',
      format: ({ vuReal }) => specialFormatStringNumber(vuReal, true, 0, 2)
    },
    {
      name: 'Vu Smkt',
      selector: 'vuSmkt',
      format: ({ vuSmkt }) => specialFormatStringNumber(vuSmkt, true, 0, 2)
    },
    {
      name: 'Nivel de Servicio',
      selector: 'nivelServicio',
      format: ({ nivelServicio }) => specialFormatStringNumber(nivelServicio, true, 0, 2)
    },
    {
      name: 'Distribucion Normal',
      selector: 'distribucionNormal',
      format: ({ distribucionNormal }) => specialFormatStringNumber(distribucionNormal, true, 0, 2)
    },
    {
      name: 'Demanda',
      selector: 'demanda',
      format: ({ demanda }) => specialFormatStringNumber(demanda, true, 0, 2)
    },
    {
      name: 'Forecast',
      selector: 'forecast',
      format: ({ forecast }) => specialFormatStringNumber(forecast, true, 0, 2)
    },
    {
      name: 'Error Absoluto',
      selector: 'errorAbsoluto',
      format: ({ errorAbsoluto }) => specialFormatStringNumber(errorAbsoluto, true, 0, 2)
    },
    {
      name: 'Wmape',
      selector: 'wmape',
      format: ({ wmape }) => specialFormatStringNumber(wmape, true, 0, 5),
      wrap: true
    },
    {
      name: 'Lead Time Calculado',
      selector: 'leadTimeCalculado',
      format: ({ leadTimeCalculado }) => specialFormatStringNumber(leadTimeCalculado, true, 0, 5),
      wrap: true
    },
    {
      name: 'Sd Lead Time Al Cuadrado',
      selector: 'sdLeadTimeAlCuadrado',
      format: ({ sdLeadTimeAlCuadrado }) =>
        specialFormatStringNumber(sdLeadTimeAlCuadrado, true, 0, 5),
      wrap: true
    },
    {
      name: 'Cycle Time Distribucion',
      selector: 'cycleTimeDistribucion',
      format: ({ cycleTimeDistribucion }) =>
        specialFormatStringNumber(cycleTimeDistribucion, true, 0, 5)
    },
    {
      name: 'Cycle Time Produccion',
      selector: 'cycleTimeProduccion',
      format: ({ cycleTimeProduccion }) =>
        specialFormatStringNumber(cycleTimeProduccion, true, 0, 5)
    },
    {
      name: 'SD Supply',
      selector: 'sdSupply',
      format: ({ sdSupply }) => specialFormatStringNumber(sdSupply, true, 0, 2)
    },
    {
      name: 'Safety Stock Dias',
      selector: 'safetyStockDias',
      format: ({ safetyStockDias }) => specialFormatStringNumber(safetyStockDias, true, 0, 0),
      wrap: true
    },
    {
      name: 'Reorder Point Dias',
      selector: 'reorderPointDias',
      format: ({ reorderPointDias }) => specialFormatStringNumber(reorderPointDias, true, 0, 0),
      wrap: true
    },
    {
      name: 'Inventario Promedio Dias',
      selector: 'inventarioPromedioDias',
      format: ({ inventarioPromedioDias }) =>
        specialFormatStringNumber(inventarioPromedioDias, true, 0, 0),
      wrap: true
    },
    {
      name: 'Inventario Maximo Dias',
      selector: 'inventarioMaximoDias',
      format: ({ inventarioMaximoDias }) =>
        specialFormatStringNumber(inventarioMaximoDias, true, 0, 0),
      wrap: true
    },
    ...(tableColumnsAcumulated as any)
  ],

  fields: [],

  editableFields: [],

  filterFields: [
    {
      name: 'Politica',
      selector: 'politica',
      type: FieldTypes.Text
    },
    {
      name: 'Sku',
      selector: 'sku',
      type: FieldTypes.Text
    },
    {
      name: 'Descr',
      selector: 'descr',
      type: FieldTypes.Text
    },
    {
      name: 'Empaque',
      selector: 'empaque',
      type: FieldTypes.Text
    },
    {
      name: 'Formato',
      selector: 'formato',
      type: FieldTypes.Text
    },
    {
      name: 'Categoria',
      selector: 'categoria',
      type: FieldTypes.Text
    },
    {
      name: 'Cd Cl',
      selector: 'cdCl',
      type: FieldTypes.Text
    },
    {
      name: 'Cl Origen',
      selector: 'clOrigen',
      type: FieldTypes.Text
    },
    {
      name: 'Sistema',
      selector: 'sistema',
      type: FieldTypes.Text
    },
    {
      name: 'Clasificacion Abc Pais',
      selector: 'clasificacionAbcPais',
      type: FieldTypes.Text
    },
    {
      name: 'Clasificacion Abc Cd',
      selector: 'clasificacionAbcCd',
      type: FieldTypes.Text
    }
  ],

  exportColumns: [
    {
      name: 'Politica',
      selector: 'politica',
      format: 'text'
    },
    {
      name: 'Sku',
      selector: 'sku',
      format: 'text'
    },
    {
      name: 'Descr',
      selector: 'descr',
      format: 'text'
    },
    {
      name: 'Empaque',
      selector: 'empaque',
      format: 'text'
    },
    {
      name: 'Formato',
      selector: 'formato',
      format: 'text'
    },
    {
      name: 'Categoria',
      selector: 'categoria',
      format: 'text'
    },
    {
      name: 'Cd Cl',
      selector: 'cdCl',
      format: 'text'
    },
    {
      name: 'Cl Origen',
      selector: 'clOrigen',
      format: 'text'
    },
    {
      name: 'Sistema',
      selector: 'sistema',
      format: 'text'
    },
    {
      name: 'Clasificacion Abc Pais',
      selector: 'clasificacionAbcPais',
      format: 'text'
    },
    {
      name: 'Clasificacion Abc Cd',
      selector: 'clasificacionAbcCd',
      format: 'text'
    },
    {
      name: 'Vu Real',
      selector: 'vuReal',
      format: 'numeric'
    },
    {
      name: 'Vu Smkt',
      selector: 'vuSmkt',
      format: 'numeric'
    },
    {
      name: 'Nivel de Servicio',
      selector: 'nivelServicio',
      format: 'numeric'
    },
    {
      name: 'Distribucion Normal',
      selector: 'distribucionNormal',
      format: 'numeric'
    },
    {
      name: 'Demanda',
      selector: 'demanda',
      format: 'numeric'
    },
    {
      name: 'Forecast',
      selector: 'forecast',
      format: 'numeric'
    },
    {
      name: 'Error Absoluto',
      selector: 'errorAbsoluto',
      format: 'numeric'
    },
    {
      name: 'Wmape',
      selector: 'wmape',
      format: 'numeric',
      maximumFloat: 10
    },
    {
      name: 'Lead Time Calculado',
      selector: 'leadTimeCalculado',
      format: 'numeric',
      maximumFloat: 5
    },
    {
      name: 'Sd Lead Time Al Cuadrado',
      selector: 'sdLeadTimeAlCuadrado',
      format: 'numeric',
      maximumFloat: 5
    },
    {
      name: 'Cycle Time Distribucion',
      selector: 'cycleTimeDistribucion',
      format: 'numeric',
      maximumFloat: 5
    },
    {
      name: 'Cycle Time Produccion',
      selector: 'cycleTimeProduccion',
      format: 'numeric',
      maximumFloat: 5
    },
    {
      name: 'Sd Supply',
      selector: 'sdSupply',
      format: 'numeric'
    },
    {
      name: 'Safety Stock Dias',
      selector: 'safetyStockDias',
      format: 'numeric'
    },
    {
      name: 'Reorder Point Dias',
      selector: 'reorderPointDias',
      format: 'numeric'
    },
    {
      name: 'Inventario Promedio Dias',
      selector: 'inventarioPromedioDias',
      format: 'numeric'
    },
    {
      name: 'Inventario Maximo Dias',
      selector: 'inventarioMaximoDias',
      format: 'numeric'
    },
    ...(exportColumnsAcumulated as any)
  ]
};

export default PoliticasInventarioCdClEntity;
