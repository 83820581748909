import { Constantes, ConstantesValueTypes } from '../types';
import { FieldTypes, WebEntity } from './types';

const ConstantesEntity: WebEntity<Constantes> = {
  name: 'constantes',
  endpoint: 'constantes',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Nombre',
      selector: 'nombre'
    },
    {
      name: 'Descripción',
      selector: 'descripcion',
      wrap: true
    },
    {
      name: 'Valor Decimal',
      selector: 'valorNumeric'
    },
    {
      name: 'Valor Entero',
      selector: 'valorInteger'
    },
    {
      name: 'Valor Texto',
      selector: 'valorText'
    }
  ],

  fields: [],

  editableFields: [
    {
      name: 'Nombre',
      selector: 'nombre',
      type: FieldTypes.Text,
      disabled: true
    },
    {
      name: 'Descripción',
      selector: 'descripcion',
      type: FieldTypes.Text,
      disabled: true
    },
    {
      name: 'Tipo de valor',
      selector: 'valueType',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'valueType',
        select: 'value',
        show: 'option',
        data: [
          { option: 'Valor Decimal', value: ConstantesValueTypes.valorNumeric },
          { option: 'Valor Entero', value: ConstantesValueTypes.valorInteger },
          { option: 'Valor Texto', value: ConstantesValueTypes.valorText }
        ]
      }
    },
    {
      name: 'Valor',
      selector: 'inputValue',
      required: true,
      type: FieldTypes.Text
    }
  ],
  exportColumns: [
    {
      name: 'Nombre',
      selector: 'nombre',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Descripción',
      selector: 'descripcion',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Valor Decimal',
      selector: 'valorNumeric',
      format: 'numeric',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Valor Entero',
      selector: 'valorInteger',
      format: 'numeric',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Valor Texto',
      selector: 'valorText',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    }
  ]
};

export default ConstantesEntity;
