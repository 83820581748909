import { NivelServicio } from '../types';
import { WebEntity } from './types';
import { specialFormatStringNumber } from '../utils/utils';

const NivelServicioEntity: WebEntity<NivelServicio> = {
  name: 'nivelServicio',
  endpoint: 'nivelServicio',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Categoria',
      selector: 'categoria'
    },
    {
      name: 'Abc',
      selector: 'abc'
    },
    {
      name: 'Nivel Servicio',
      selector: 'nivelServicio',
      format: ({ nivelServicio }) => specialFormatStringNumber(nivelServicio, true, 0, 2)
    },
    {
      name: 'Distribucion Normal',
      selector: 'distribucionNormal',
      format: ({ distribucionNormal }) => specialFormatStringNumber(distribucionNormal, true, 0, 2)
    }
  ],

  fields: [],

  editableFields: [],
  exportColumns: [
    {
      name: 'Categoria',
      selector: 'categoria',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Abc',
      selector: 'abc',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Nivel Servicio',
      selector: 'nivelServicio',
      format: 'numeric',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Distribucion Normal',
      selector: 'distribucionNormal',
      format: 'numeric',
      decimalSeparator: ',',
      thousandSeparator: '.'
    }
  ]
};

// deafult 'key': {'name':'name','required':true,'export':true}
export const NIV_SER_COLUMNS = {
  categoria: 'Categoria',
  abc: 'Abc',
  nivelServicio: 'Nivel Servicio',
  distribucionNormal: 'Distribucion Normal'
};

export default NivelServicioEntity;
