import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { AuthState } from '../reducers/types';
import { AppState } from '../store';
import { UserTypeValues } from '../types';
import GenericDropdownMenu from './generics/GenericDropdownMenu/GenericDropdownMenu';

const adminTree = [
  {
    id: 1,
    label: 'Mantenedores',
    isOpened: false,
    icon: 'databaseIcon',
    children: [
      {
        id: 1001,
        label: 'Usuarios',
        isOpened: false,
        href: '/mantenedores/Usuario'
      },
      {
        id: 1002,
        label: 'Tipo de Usuario',
        isOpened: false,
        href: '/mantenedores/tipo-usuario'
      },
      {
        id: 1003,
        label: 'SKU',
        isOpened: false,
        href: '/mantenedores/sku'
      },
      {
        id: 1004,
        label: 'Matriz Acarreo',
        isOpened: false,
        href: '/mantenedores/matriz-acarreo'
      },
      {
        id: 1005,
        label: 'Matriz Producción',
        isOpened: false,
        href: '/mantenedores/matriz-produccion'
      },
      {
        id: 1006,
        label: 'Cycle Time Producción',
        isOpened: false,
        href: '/mantenedores/cycle-time-produccion'
      },
      {
        id: 1007,
        label: 'Cycle Time Distribución',
        isOpened: false,
        href: '/mantenedores/cycle-time-distribucion'
      },
      {
        id: 1008,
        label: 'Nivel Servicio',
        isOpened: false,
        href: '/mantenedores/nivel-servicio'
      },
      {
        id: 1009,
        label: 'Constantes',
        isOpened: false,
        href: '/mantenedores/constantes'
      },
      {
        id: 1010,
        label: 'Locaciones CD',
        isOpened: false,
        href: '/mantenedores/locaciones-cd'
      },
      {
        id: 1011,
        label: 'Diccionario Locaciones CD',
        isOpened: false,
        href: '/mantenedores/diccionario-locaciones-cd'
      },
      {
        id: 1012,
        label: 'Locaciones CL',
        isOpened: false,
        href: '/mantenedores/locaciones-cl'
      },
      {
        id: 1013,
        label: 'Diccionario Locaciones CL',
        isOpened: false,
        href: '/mantenedores/diccionario-locaciones-cl'
      }
    ]
  }
];

const operatorTree = [
  {
    id: 2,
    label: 'Inconsistencias',
    isOpened: false,
    icon: 'inconsistenciesIcon',
    children: [
      {
        id: 2001,
        label: 'Reporte Cantidad',
        isOpened: false,
        href: '/inconsistencias/reporte-cantidad'
      },
      {
        id: 2002,
        label: 'Registros Faltantes',
        isOpened: false,
        href: '/inconsistencias/registros-faltantes'
      },
      { id: 2003, label: 'Categoria', isOpened: false, href: '/inconsistencias/categoria' },
      {
        id: 2004,
        label: 'Cycle Time Produccion',
        isOpened: false,
        href: '/inconsistencias/cycle-time-produccion'
      },
      /*{
        id: 2005,
        label: 'Matriz Acarreo',
        isOpened: false,
        href: '/inconsistencias/matriz-acarreo'
      },*/
      {
        id: 2006,
        label: 'Rutas Incompletas',
        isOpened: false,
        href: '/inconsistencias/rutas-incompletas'
      },
      {
        id: 2007,
        label: 'Matriz Produccion',
        isOpened: false,
        href: '/inconsistencias/matriz-produccion'
      }
      /*{
        id: 2008,
        label: 'Variables Base CL',
        isOpened: false,
        href: '/inconsistencias/variables-base-cl'
      },
      {
        id: 2009,
        label: 'Variables Base CD',
        isOpened: false,
        href: '/inconsistencias/variables-base-cd'
      }*/
    ]
  },
  {
    id: 3,
    label: 'Reporte CD + CL',
    isOpened: false,
    icon: 'reportIcon',
    href: '/politicas/reportes-cd-cl'
  }
];

type Props = {
  auth: AuthState;
};

const AppNavbar: FunctionComponent<Props> = ({ auth }) => {
  const { isAuthenticated, user } = auth;

  return (
    <div>
      <div>
        {isAuthenticated &&
        (user!.userTypeName === UserTypeValues.Admin ||
          [UserTypeValues.Admin as string, UserTypeValues.Operador as string].includes(
            user!.userTypeName
          )) ? (
          <div>
            {
              <GenericDropdownMenu
                menu={
                  auth.user?.userTypeName === UserTypeValues.Admin
                    ? [...adminTree, ...operatorTree]
                    : operatorTree
                }
              />
            }{' '}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default connect(({ auth }: AppState) => ({ auth }))(AppNavbar);
