import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../store';
import { AuthState } from '../reducers/types';
import { UserTypeValues } from '../types';
import { Redirect } from 'react-router-dom';

interface IProps {
  auth: AuthState;
}

const AnonimousPage: FunctionComponent<IProps> = ({ auth }) => {
  const { isAuthenticated, user } = auth;
  const allowedUserTypes = [UserTypeValues.Admin as string, UserTypeValues.Operador as string];

  if (isAuthenticated) {
    if (allowedUserTypes.includes(user!.userTypeName)) {
      return <Redirect to='/politicas/reportes-cd-cl' />;
    }
  }

  return (
    <div style={{ marginInline: '2em' }}>
      <h1> Bienvenido! Por favor inicie sesión para empezar a utilizar la aplicación </h1>
    </div>
  );
};

export default connect(({ auth }: AppState) => ({ auth }))(AnonimousPage);
