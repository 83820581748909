import { CSSProperties } from 'react';
import { connect, ConnectedComponent } from 'react-redux';
import {
  addEntityAction,
  multiAddEntityAction,
  deleteEntityAction,
  editEntityAction,
  getEntitiesAction,
  voidEntitiesAction,
  setSelectedEntityAction,
  setSingleSelectedEntityAction,
  notifyMessageEntityAction
} from '../actions/entitiesActions';
import { WebEntity, WebEntityName } from '../entities/types';
import {
  ADD_FAIL_ENTITY,
  AUTH_ERROR,
  DELETE_FAIL_ENTITY,
  EDIT_FAIL_ENTITY,
  NOTIFY_MESSAGE_ENTITY
} from '../reducers/types';
import { AppState } from '../store';
import { BasicEntity, SimpleObject } from '../types';
import GenericCRUD from './generics/GenericCRUD';
import {
  AdditionalTableActionsFunc,
  AdditionalTableProps,
  ColumnComponent,
  CRUDAllowedActions
} from './types';
import { ExportColumns } from '../entities/types';

interface OptionalParams<T> {
  title: string;
  GETPath: string;
  dummy: boolean;
  serverSidePagination: boolean;
  fetchByNRows: number;
  limitRows: number;
  query: any;
  sharedFilterName?: string;
  additionalTableProps: AdditionalTableProps<T>;
  additionalTableActions: AdditionalTableActionsFunc<T>;
  columnComponent: ColumnComponent<T>;
  allowedActions: Partial<CRUDAllowedActions<T>>;
  preProcessEntityList: (entityList: Array<any>) => Array<any>;
  customExport?: {
    endpoint: string;
    COLUMNS: Array<ExportColumns>;
    buttonName?: string;
    fileName?: string;
  };
  liveFilter: boolean;
  style: CSSProperties;
  headerScroll?: boolean;
  headerScrollHeight?: string;
  viewInputFilter: boolean;
}

interface CRUDParams<T> extends Partial<OptionalParams<T>> {
  webEntity: WebEntity<T>;
}

/**
 * Function to generate CRUD connected with react-redux for an specified entity.
 *
 * @param {WebEntity<T>}  webEntity: The web entity that describe the CRUD to create
 * @param {string}   title: title of list
 * @param {string}   GETPath: an aditional path to get the element list /api/{entityName}/{GETPath}
 * @param {boolean}  serverSidePagination: bool - get paginated data from the endpoint
 * @param {number}   fetchByNRows: fetch the data from the endpoint by N rows until get all the data
 * @param {number}   limitRows: fetch a limit of N of rows
 * @param {any}      query: a query to filter the elements shown in the list /api/{entityName}/{GETPath}/{filter}
 *                   recives an object and automatically parse it to url query format.
 * @param {AdditionalTableProps} additionalTableProps: object with customization options for GenericTable
 * @param {AdditionalTableActionsFunc} additionalTableActions: function to render into the actions for selected items in the table
 * @param {CRUDAllowedActions} allowedActions: Allow you to enable or disable CRUD actions: (add, delete, export and select)
 * @param {function} columnComponent: {begin: bool, column: {cell: component, allowOverFlow, bool, button: bool, width: str}}
 * @param {boolean}  liveFilter: Boolean - TRUE: filter as soon you text in the input, FALSE: filter on press ENTER
 * @param {any}      style: style for CRUD cointainer
 */
export const generateCRUD = <T extends BasicEntity>({
  webEntity,
  title,
  GETPath,
  dummy,
  serverSidePagination,
  fetchByNRows,
  limitRows,
  query,
  sharedFilterName,
  additionalTableProps,
  additionalTableActions,
  columnComponent,
  allowedActions,
  preProcessEntityList,
  customExport,
  liveFilter,
  style,
  headerScroll,
  headerScrollHeight,
  viewInputFilter
}: CRUDParams<T>): ConnectedComponent<any, any> => {
  allowedActions = {
    add: true,
    delete: true,
    edit: false,
    export: false,
    multiLineForm: false,
    ...allowedActions
  };
  allowedActions.select = {
    enable: true,
    single: false,
    pageOnly: false,
    rowDisabledCriteria: undefined,
    ...allowedActions.select
  };

  const mapStateToProps = ({ entities, auth, error }: AppState) => ({
    entities,
    error,
    auth
  });

  // Generate Action Functions for entity
  const getEntities =
    webEntity.endpoint && !dummy
      ? getEntitiesAction(webEntity.name as WebEntityName, webEntity.endpoint, {
          GETPath,
          query,
          fetchByNRows,
          limitRows
        })
      : voidEntitiesAction(webEntity.name as WebEntityName);

  const selectedEntities = setSelectedEntityAction(webEntity.name as WebEntityName);
  const singleSelectedEntity = setSingleSelectedEntityAction(webEntity.name as WebEntityName);
  const deleteEntity = deleteEntityAction(webEntity.name as WebEntityName, webEntity.endpoint);
  const addEntity = addEntityAction(webEntity.name as WebEntityName, webEntity.endpoint);
  const multiAddEntity = multiAddEntityAction(webEntity.name as WebEntityName, webEntity.endpoint);
  const editEntity = editEntityAction(webEntity.name as WebEntityName, webEntity.endpoint);
  const notifyMessageEntity = notifyMessageEntityAction(webEntity.name as WebEntityName);

  const DEL_FAIL_FOR_THIS_ENTITY = `${DELETE_FAIL_ENTITY}${webEntity.name.toUpperCase()}`;
  const ADD_FAIL_FOR_THIS_ENTITY = `${ADD_FAIL_ENTITY}${webEntity.name.toUpperCase()}`;
  const EDIT_FAIL_FOR_THIS_ENTITY = `${EDIT_FAIL_ENTITY}${webEntity.name.toUpperCase()}`;
  const NOTIFY_MESSAGE_FOR_THIS_ENTITY = `${NOTIFY_MESSAGE_ENTITY}${webEntity.name.toUpperCase()}`;

  const signalsToWatch = [
    AUTH_ERROR,
    DEL_FAIL_FOR_THIS_ENTITY,
    ADD_FAIL_FOR_THIS_ENTITY,
    EDIT_FAIL_FOR_THIS_ENTITY,
    NOTIFY_MESSAGE_FOR_THIS_ENTITY
  ];
  const mapDispatchToProps = (
    dispatch: any,
    ownProps = {
      webEntity,
      title,
      signalsToWatch,
      additionalTableProps,
      additionalTableActions,
      columnComponent,
      serverSidePagination,
      allowedActions,
      preProcessEntityList,
      sharedFilterName,
      customExport,
      liveFilter,
      style,
      headerScroll,
      headerScrollHeight,
      viewInputFilter
    }
  ) => ({
    webEntity: ownProps.webEntity,
    title: ownProps.title !== undefined ? ownProps.title : ownProps.webEntity.name,
    signalsToWatch: ownProps.signalsToWatch,
    additionalTableProps: ownProps.additionalTableProps,
    additionalTableActions: ownProps.additionalTableActions,
    columnComponent: ownProps.columnComponent,
    serverSidePagination: ownProps.serverSidePagination,
    allowedActions: ownProps.allowedActions,
    preProcessEntityList: ownProps.preProcessEntityList,
    sharedFilterName: ownProps.sharedFilterName,
    customExport: ownProps.customExport,
    liveFilter: ownProps.liveFilter,
    style: ownProps.style,
    headerScroll: ownProps.headerScroll,
    headerScrollHeight: ownProps.headerScrollHeight,
    viewInputFilter: ownProps.viewInputFilter,
    getEntities: (pageNumber?: number, pageSize?: number, filters?: any) =>
      dispatch(getEntities(pageNumber, pageSize, filters)),
    selectedEntities: (selected: T[]) => dispatch(selectedEntities(selected)),
    singleSelectedEntity: (selected: T) => dispatch(singleSelectedEntity(selected)),
    deleteEntity: (id: string[]) => dispatch(deleteEntity(id)),
    addEntity: (entity: T | T[]) => dispatch(addEntity(entity)),
    multiAddEntity: (entity: T | T[]) => dispatch(multiAddEntity(entity)),
    editEntity: (entity: T) => dispatch(editEntity(entity)),
    notifyMessageEntity: (msg: string) => dispatch(notifyMessageEntity(msg))
  });

  // This connect receives
  // a function to map the redux states into props for this component,
  // a props list which are additional props
  return connect(mapStateToProps, mapDispatchToProps)(GenericCRUD);
};
