import React, { FunctionComponent, useMemo, useState } from 'react';
import { Button } from 'semantic-ui-react';
import axios, { AxiosResponse } from 'axios';
import { SimpleObject } from '../types';
import { storedTokenConfig } from '../utils/header';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { convertArrayOfObjectToFieldsMatrix } from '../utils/export';
import { ExportColumns } from '../entities/types';
import { utils, writeFile } from 'xlsx';
import { dateFormatFn, formatStringNumber } from '../utils/utils';
import { PopAlertType, popAlert } from './PopAlert';
import '../css/asyncExportXLSX.css';

const numberFormatter = (
  cellValue: any,
  maximumFloat: number,
  thousandSep: string,
  decimalSep: string
): { cellValueFormated: string; format: string } => {
  const cellValueFormated = formatStringNumber(cellValue, true, 0, maximumFloat)
    .replace(new RegExp(`[${thousandSep}]`, 'g'), '')
    .replace(new RegExp(`[${decimalSep}]`, 'g'), '.');

  const thousandFormat =
    maximumFloat && maximumFloat > 0 ? `#,##0.${'#'.repeat(maximumFloat)}` : '#,##0.##';
  const decimalFormat =
    maximumFloat && maximumFloat > 0 ? `#,${'#'.repeat(maximumFloat)}0` : '#,##0';
  const format = cellValue % 1 ? thousandFormat : decimalFormat;
  return { cellValueFormated, format };
};

type DateFormatType = 'date' | 'dateTime' | 'dateUTC' | 'dateTimeUTC';

const dateFormatter = (cellValue: any, format: DateFormatType): string => {
  if (format === 'date') {
    return dateFormatFn(cellValue, 'DD/MM/YYYY');
  } else if (format === 'dateTime') {
    return dateFormatFn(cellValue, 'DD/MM/YYYY HH:mm:ss');
  } else if (format === 'dateUTC') {
    return dateFormatFn(cellValue, 'DD/MM/YYYY', true);
  } else if (format === 'dateTimeUTC') {
    return dateFormatFn(cellValue, 'DD/MM/YYYY HH:mm:ss', true);
  }
  return cellValue;
};

const dateValidator = (date: string, format: DateFormatType) => {
  let formatoRegex;
  if (['date', 'dateUTC'].includes(format)) {
    formatoRegex = /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/(\d{4})$/;
  } else if (['dateTime', 'dateTimeUTC'.includes(format)]) {
    formatoRegex =
      /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/(\d{4})\s+(\d{2}):(\d{2}):(\d{2})$/;
  } else {
    return false;
  }
  return formatoRegex.test(date);
};

const exportXLSX = async (
  fetchedData: Array<SimpleObject>,
  COLUMNS: Array<ExportColumns>,
  callback: (infoMsg: string, currentPercentage: number, processing: boolean) => void,
  fileName?: string
) => {
  callback('Iniciando procesamiento...', 1, true);
  const data = convertArrayOfObjectToFieldsMatrix(
    fetchedData,
    COLUMNS.map((col) => col.selector),
    COLUMNS.map((c: any) => c.name)
  );
  const sheet = utils.aoa_to_sheet(data);

  const batchSize = data.length > 100 ? 100 : data.length;
  let processedCount = 0;
  const processBatch = () => {
    const endIndex = Math.min(processedCount + batchSize, data.length);
    for (let rowIndex = processedCount; rowIndex < endIndex; rowIndex++) {
      COLUMNS.forEach((c, cIdx) => {
        const columnLetter = utils.encode_col(cIdx);
        const cellAddress = columnLetter + (rowIndex + 1);
        if (sheet[cellAddress]) {
          const cellValue = sheet[cellAddress].v;

          if (c.format === 'numeric') {
            const res = numberFormatter(
              cellValue,
              c.maximumFloat || 2,
              c.thousandSeparator || '.',
              c.decimalSeparator || ','
            );
            if (!isNaN(cellValue) && res.cellValueFormated) {
              sheet[cellAddress].v = res.cellValueFormated;
              sheet[cellAddress].z = res.format;
              sheet[cellAddress].t = 'n';
            }
          } else if (['date', 'dateTime', 'dateUTC', 'dateTimeUTC'].includes(c.format)) {
            const res = dateFormatter(cellValue, c.format as DateFormatType);
            if (dateValidator(res, c.format as DateFormatType)) {
              sheet[cellAddress].v = res.replaceAll('/', '-');
            }
          }
        }
      });
    }

    processedCount += batchSize;
    const currentPercentage = (processedCount / data.length) * 100;
    callback(
      `Procesando ${formatStringNumber(processedCount.toString())}/${formatStringNumber(
        data.length.toString()
      )}`,
      currentPercentage,
      true
    );

    if (processedCount < data.length) {
      setTimeout(processBatch, 0);
    } else {
      callback('Exportando...', 100, true);
      const batchSizeData = 1000000;
      const processedData = utils.sheet_to_json(sheet);
      const numSheets = Math.ceil(processedData.length / batchSizeData);
      const workbook = utils.book_new();
      for (let i = 0; i < numSheets; i++) {
        const start = i * batchSizeData;
        const end = Math.min(start + batchSizeData, processedData.length);
        const dataSubset = processedData.slice(start, end);
        const ws = utils.json_to_sheet(dataSubset);
        utils.book_append_sheet(workbook, ws, `Hoja ${i + 1}`);
      }
      writeFile(workbook, fileName ? `${fileName}.xlsx` : 'export.xlsx');
      callback('Exportado', 0, false);
    }
  };
  processBatch();
};

type ProcessProgressData = {
  percentage: number;
  data: Array<SimpleObject>;
  message: string;
  done: boolean;
} | null;

const getData = async (
  config: { headers: { [key: string]: string } },
  endpoint: string,
  callback: (res: AxiosResponse<ProcessProgressData> | null) => void
) => {
  let processUUID: string | null = null;

  try {
    const response = await axios.get(`/api/${endpoint}/newProcessUUID`, {
      headers: config.headers
    });
    processUUID = response.data;

    const interval = setInterval(async () => {
      try {
        if (!processUUID) {
          clearInterval(interval);
          return;
        }

        const progressRes = await axios.get(
          `/api/${endpoint}/checkReadDataProgress/${processUUID}`,
          { headers: config.headers }
        );

        if (!progressRes.data || progressRes.data.done) {
          clearInterval(interval);
          callback(progressRes);
        } else {
          callback(progressRes);
        }
      } catch (error) {
        clearInterval(interval);
        callback(null);
      }
    }, 1000);

    await axios.post(`/api/${endpoint}`, { processUUID }, { headers: config.headers });
  } catch (error) {
    popAlert({
      type: PopAlertType.ERROR,
      title:
        (error as any).message ||
        (error as any).response?.data?.msg ||
        (error as any).response?.statusText ||
        '',
      details: (error as any).response?.data?.details
    });
    console.log(error);
    callback(null);
  }
};

const AsyncExportXLSX: FunctionComponent<{
  endpoint: string;
  COLUMNS: Array<ExportColumns>;
  buttonName?: string;
  fileName?: string;
}> = ({ endpoint, COLUMNS, buttonName, fileName }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [progressMessage, setProgressMessage] = useState<string>('');
  const [progressPercentage, setProgressPercentage] = useState<number>(0);

  const config = useMemo(() => storedTokenConfig(), []);

  const onClick = async () => {
    setLoading(true);
    setProgressMessage('Realizando solicitud...');
    await getData(config, endpoint, async (res: AxiosResponse<ProcessProgressData> | null) => {
      if (res === null) {
        setProgressMessage('');
        setProgressPercentage(0);
        setLoading(false);
        return;
      }

      if (res.data && res.data.done) {
        if (res.status === 200) {
          try {
            setProgressMessage('Iniciando Procesamiento');
            setProgressPercentage(0.1);
            setLoading(true);
            await exportXLSX(
              res.data?.data,
              COLUMNS,
              async (infoMsg: string, currentPercentage: number, processing: boolean) => {
                setProgressMessage(infoMsg);
                setProgressPercentage(currentPercentage);
                setLoading(processing);
              },
              fileName
            );
          } catch (error) {
            console.log(error);
            setLoading(false);
            setProgressMessage('');
            setProgressPercentage(0);
          }
        }
        return;
      }

      if (!res.data) {
        setProgressMessage('');
        setProgressPercentage(0);
        setLoading(false);
        return;
      }

      setProgressMessage(res.data.message);
      setProgressPercentage(Math.round(res.data.percentage));
    });
  };

  return (
    <div className='async-export-xlsx-container'>
      {progressPercentage !== 0 || loading ? (
        <>
          <p>{progressMessage}</p>
          <ProgressBar
            animated
            variant='success'
            now={progressPercentage}
            label={`${progressPercentage.toFixed(2)}%`}
            visuallyHidden
          />
        </>
      ) : (
        <Button
          loading={progressPercentage !== 0 || loading}
          disabled={progressPercentage !== 0 || loading}
          onClick={() => onClick()}
          color='blue'
          className='async-export-xlsx-button'>
          {buttonName ? buttonName : 'Exportar'}
        </Button>
      )}
    </div>
  );
};

export default AsyncExportXLSX;
