import React, { CSSProperties, useState } from 'react';
import update from '../../img/CloudArrowUp-r.svg';
import datafy from '../../img/Logo.svg';
import logout from '../../img/SignOut-r.svg';
import avatar from '../../img/UserCircle-r.svg';
import edit from '../../img/PencilLine-r.svg';
import filter from '../../img/Funnel-r.svg';
import add from '../../img/Plus-r.svg';
import arrowLeft from '../../img/arrowLeftMenu.png';
import manteiner from '../../img/FolderNotch-d.svg';
import count from '../../img/ListNumbers-r.svg';
import arrow from '../../img/ArrowLineRight-s.svg';
import info from '../../img/Info-r.svg';
import infoWhite from '../../img/Info-rWhite.svg';
import report from '../../img/ClipboardText.svg';
import table from '../../img/Table-r.svg';
import trash from '../../img/Trash-r.svg';
import trashWhite from '../../img/trash_white.png';
import Eye from '../../img/Eye-r.svg';
import duplicate from '../../img/duplicate.png';
import duplicateWhite from '../../img/duplicate_White.png';
import databaseIcon from '../../img/databaseIcon.png';
import inconsistenciesIcon from '../../img/inconsistenciesIcon.png';
import reportIcon from '../../img/reportIcon.png';

type Props = {
  icon?: string;
  imageStyle?: CSSProperties;
};

const GenericIcons = (props: Props) => {
  const { icon, imageStyle } = props;

  const [iconData, setIconData] = useState([
    {
      id: 1,
      icon: 'update',
      svg: update
    },
    {
      id: 2,
      icon: 'datafy',
      svg: datafy
    },
    {
      id: 3,
      icon: 'logout',
      svg: logout
    },
    {
      id: 5,
      icon: 'mantainer',
      svg: manteiner
    },
    {
      id: 6,
      icon: 'count',
      svg: count
    },
    {
      id: 7,
      icon: 'arrow',
      svg: arrow
    },
    {
      id: 4,
      icon: 'avatar',
      svg: avatar
    },
    {
      id: 8,
      icon: 'edit',
      svg: edit
    },
    {
      id: 9,
      icon: 'filter',
      svg: filter
    },
    {
      id: 10,
      icon: 'add',
      svg: add
    },
    {
      id: 11,
      icon: 'arrowLeft',
      svg: arrowLeft
    },
    {
      id: 12,
      icon: 'info',
      svg: info
    },
    {
      id: 13,
      icon: 'infoWhite',
      svg: infoWhite
    },
    {
      id: 14,
      icon: 'report',
      svg: report
    },
    {
      id: 15,
      icon: 'table',
      svg: table
    },
    {
      id: 16,
      icon: 'trash',
      svg: trash
    },
    {
      id: 17,
      icon: 'eye',
      svg: Eye
    },
    {
      id: 18,
      icon: 'duplicate',
      svg: duplicate
    },
    {
      id: 19,
      icon: 'trashWhite',
      svg: trashWhite
    },
    {
      id: 20,
      icon: 'duplicateWhite',
      svg: duplicateWhite
    },
    {
      id: 21,
      icon: 'databaseIcon',
      svg: databaseIcon
    },
    {
      id: 22,
      icon: 'inconsistenciesIcon',
      svg: inconsistenciesIcon
    },
    {
      id: 23,
      icon: 'reportIcon',
      svg: reportIcon
    }
  ]);

  const findIcon = (icon_: string) => {
    const icon = iconData.find((p) => p.icon === icon_);

    return icon ? icon?.svg : null;
  };

  const SVGIcon = icon ? findIcon(icon) : null;

  return SVGIcon ? <img src={SVGIcon} alt='' style={{ marginRight: 10, ...imageStyle }} /> : null;
};

export default GenericIcons;
