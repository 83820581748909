import { BasicEntity } from '../types';
import {
  ADD_ENTITY,
  DELETE_ENTITY,
  EDIT_ENTITY,
  EntitiesActions,
  EntitiesState,
  GET_ENTITY,
  LOADING_ENTITY,
  SELECTED_ENTITY,
  SINGLE_SELECTED_ENTITY,
  NOTIFY_MESSAGE_ENTITY,
  REFRESH_ENTITY
} from './types';

const DefaultEntityState = {
  list: [],
  selected: [],
  singleSelected: null,
  loading: false,
  notificationMessage: ''
};

const initialState: EntitiesState = {
  //Mantenedores
  userType: DefaultEntityState,
  user: DefaultEntityState,
  item: DefaultEntityState,
  matrizAcarreo: DefaultEntityState,
  categoria: DefaultEntityState,
  pais: DefaultEntityState,
  sistema: DefaultEntityState,
  matrizProduccion: DefaultEntityState,
  cycleTimeProduccion: DefaultEntityState,
  cycleTimeDistribucion: DefaultEntityState,
  nivelServicio: DefaultEntityState,
  constantes: DefaultEntityState,
  locationCd: DefaultEntityState,
  locationDictionaryCd: DefaultEntityState,
  locationCl: DefaultEntityState,
  locationDictionaryCl: DefaultEntityState,
  //Politicas
  politicasInventarioCdCl: DefaultEntityState,
  //Reportes inconsistencias
  inconsistenciasCategoria: DefaultEntityState,
  inconsistenciasCycleTimeProduccion: DefaultEntityState,
  inconsistenciasMatrizAcarreo: DefaultEntityState,
  inconsistenciasRutasIncompletas: DefaultEntityState,
  inconsistenciasMatrizProduccion: DefaultEntityState,
  inconsistenciasVariablesBaseCl: DefaultEntityState,
  inconsistenciasVariablesBaseCd: DefaultEntityState,
  inconsistenciasReporteCantidad: DefaultEntityState,
  inconsistenciasRegistrosFaltantes: DefaultEntityState
};

const entitiesReducer = (state = initialState, action: EntitiesActions): EntitiesState => {
  switch (action.type) {
    case GET_ENTITY:
      return {
        ...state,
        [action.entityName]: { ...state[action.entityName], list: action.payload }
      };

    case SELECTED_ENTITY:
      return {
        ...state,
        [action.entityName]: { ...state[action.entityName], selected: action.payload }
      };

    case SINGLE_SELECTED_ENTITY:
      return {
        ...state,
        [action.entityName]: { ...state[action.entityName], singleSelected: action.payload }
      };

    case DELETE_ENTITY:
      return {
        ...state,
        [action.entityName]: {
          ...state[action.entityName],
          list: (state[action.entityName].list as BasicEntity[]).filter(
            (entity) => !action.payload.includes(entity._id)
          )
        }
      };

    case EDIT_ENTITY:
      return {
        ...state,
        [action.entityName]: {
          ...state[action.entityName],
          list: (state[action.entityName].list as BasicEntity[]).map((e) =>
            e._id === action.payload._id ? action.payload : e
          )
        }
      };

    case ADD_ENTITY:
      return {
        ...state,
        [action.entityName]: {
          ...state[action.entityName],
          list: [...action.payload, ...state[action.entityName].list]
        }
      };

    case LOADING_ENTITY:
      return {
        ...state,
        [action.entityName]: { ...state[action.entityName], loading: action.payload }
      };

    case NOTIFY_MESSAGE_ENTITY:
      return {
        ...state,
        [action.entityName]: { ...state[action.entityName], notificationMessage: action.payload }
      };

    case REFRESH_ENTITY:
      return {
        ...state,
        [action.entityName]: {
          ...state[action.entityName],
          list: [...state[action.entityName].list]
        }
      };

    default:
      return state;
  }
};

export default entitiesReducer;
