import { LocationDictionaryCd } from '../types';
import { FieldTypes, WebEntity } from './types';

const LocationDictionaryCdEntity: WebEntity<LocationDictionaryCd> = {
  name: 'locationDictionaryCd',
  endpoint: 'locationDictionaryCd',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'ID CD en Politicas de Inventario',
      selector: 'locationCdId',
      wrap: true
    },
    {
      name: 'Nombre CD en Politicas de Inventario',
      selector: 'locationCdName',
      wrap: true
    },
    {
      name: 'ID CD en el Origen',
      selector: 'locationDictionaryId',
      wrap: true
    },
    {
      name: 'Nombre CD en el Origen',
      selector: 'locationDictionaryNameSource',
      wrap: true
    }
  ],

  fields: [
    {
      name: 'Nombre CD en Politicas de Inventario',
      selector: 'locationCdId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'locationCdId',
        endpoint: 'locationCd',
        select: '_id',
        show: 'locationCdName'
      }
    },
    {
      name: 'Nombre CD en el Origen',
      selector: 'locationDictionaryNameSource',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: [
    {
      name: 'Nombre CD en Politicas de Inventario',
      selector: 'locationCdId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'locationCdId',
        endpoint: 'locationCd',
        select: '_id',
        show: 'locationCdName'
      }
    },
    {
      name: 'Nombre CD en el Origen',
      selector: 'locationDictionaryNameSource',
      type: FieldTypes.Text,
      required: true
    }
  ],

  exportColumns: [
    {
      name: 'ID CD en Politicas de Inventario',
      selector: 'locationCdId',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Nombre CD en Politicas de Inventario',
      selector: 'locationCdName',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'ID CD en el Origen',
      selector: 'locationDictionaryId',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Nombre CD en el Origen',
      selector: 'locationDictionaryNameSource',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    }
  ]
};

export default LocationDictionaryCdEntity;
