import { InconsistenciasCycleTimeProduccion } from '../types';
import { WebEntity } from './types';
import { specialFormatStringNumber } from '../utils/utils';

const InconsistenciasCycleTimeProduccionEntity: WebEntity<InconsistenciasCycleTimeProduccion> = {
  name: 'inconsistenciasCycleTimeProduccion',
  endpoint: 'inconsistenciasCycleTimeProduccion',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Categoria',
      selector: 'categoria',
      wrap: true
    },
    {
      name: 'Planta',
      selector: 'planta',
      wrap: true
    },
    {
      name: 'Sistema',
      selector: 'sistema',
      wrap: true
    },
    {
      name: 'Sistema Dos',
      selector: 'sistemaDos',
      wrap: true
    },
    {
      name: 'Sku',
      selector: 'sku'
    },
    {
      name: 'Abc',
      selector: 'abc'
    },
    {
      name: 'Cycle Time',
      selector: 'cycleTime',
      format: ({ cycleTime }) => specialFormatStringNumber(cycleTime, true, 0, 2)
    },
    {
      name: 'Existe Sku En Mantenedor',
      selector: 'existeSkuEnMantenedor'
    },
    {
      name: 'Existe Planta En Mantenedor',
      selector: 'existePlantaEnMantenedor'
    },
    {
      name: 'Existe En Matriz Produccion',
      selector: 'existeEnMatrizProduccion'
    },
    {
      name: 'Tiene Cycle Time',
      selector: 'tieneCycleTime'
    }
  ],

  fields: [],
  editableFields: [],
  filterFields: [],
  exportColumns: [
    {
      name: 'Categoria',
      selector: 'categoria',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Planta',
      selector: 'planta',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Sistema',
      selector: 'sistema',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Sistema Dos',
      selector: 'sistemaDos',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Sku',
      selector: 'sku',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Abc',
      selector: 'abc',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Cycle Time',
      selector: 'cycleTime',
      format: 'numeric',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Existe Sku En Mantenedor',
      selector: 'existeSkuEnMantenedor',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Existe Planta En Mantenedor',
      selector: 'existePlantaEnMantenedor',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Existe En Matriz Produccion',
      selector: 'existeEnMatrizProduccion',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Tiene Cycle Time',
      selector: 'tieneCycleTime',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    }
  ]
};

export default InconsistenciasCycleTimeProduccionEntity;
