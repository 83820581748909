import { Categoria } from '../types';
import { WebEntity } from './types';

const CategoriaEntity: WebEntity<Categoria> = {
  name: 'categoria',
  endpoint: 'categoria',
  referenceColumn: 'categoriaId',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Categoria Id',
      selector: 'categoriaId',
      sortable: true
    },
    {
      name: 'Categoria Descripcion',
      selector: 'categoriaDescripcion',
      sortable: true
    }
  ],

  fields: [],

  editableFields: []
};

export default CategoriaEntity;
