import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import DatePicker from 'react-date-picker';
import { useRut } from 'react-rut';
import Webcam from 'react-webcam';
import { Button, Form, FormGroup, Input, Label, Modal } from 'reactstrap';
import { Button as ButtonB, Checkbox, Dropdown, Icon, Image } from 'semantic-ui-react';
import { FieldTypes } from '../../entities/types';
import { ImageFormats } from '../../types';
import { toBase64 } from '../../utils/convert';
import { getEntity } from '../../utils/request';
import { capitalize, fusionFields, cleanTextDate } from '../../utils/utils';
import { warningPopAlert } from '../PopAlert';

const PHOTO_WIDTH = 1920;
const PHOTO_HEIGHT = 1080;

const FieldInputText = ({ field, onChange, autoFocus, value, disabled = false }) => (
  <Input
    name={field.selector}
    type={field.type}
    placeholder={capitalize(field.name)}
    className='mb-3'
    onChange={onChange}
    autoFocus={autoFocus}
    value={value}
    disabled={disabled}
  />
);

const pickupDateOnChange = (callback) => (date) => callback(cleanTextDate(date));
export const DatePickerComponent = ({
  name,
  value,
  format = 'dd/MM/yyyy',
  onChange,
  minDate = undefined,
  maxDate = undefined
  //locale = 'es-CL'
}) => {
  if (typeof value === 'string' && /^\d{4}\/\d{2}\/\d{2}$/.test(value)) {
    const splittedDate = value.split('/');
    value = `${splittedDate[1]}-${splittedDate[2]}-${splittedDate[0]}`;
  }

  const localValue = typeof value === 'string' ? new Date(value) : value;
  return (
    <DatePicker
      showIcon
      name={name}
      value={localValue}
      format={format}
      onChange={pickupDateOnChange(onChange)}
      dayPlaceholder={'Día'}
      monthPlaceholder={'Mes'}
      yearPlaceholder={'Año'}
      minDate={minDate}
      maxDate={maxDate}
      className={'inputDateGlobal'}
      //locale={locale}
    />
  );
};

const FieldInputRut = ({ field, onChange, autoFocus, value }) => {
  // TODO: NO DEJAR ENVIAR EL FORMULARIO SI isValid ES false
  // TODO: ARREGLAR LA OBTENCION DEL RUT COMPLETO FORMATEADO
  // TODO: QUE SE SETEE EL VALOR CUANDO SE SELECCIONA EL VALOR GUARDADO DESDE EL NAVEGADOR
  const [{ formattedValue, rawValue }, setRut] = useRut();

  const localOnChange = (e) => {
    setRut(e.target.value);
    onChange({ target: { value: rawValue, name: field.selector } });
  };

  return (
    <Input
      name={field.selector}
      type={field.type}
      placeholder={capitalize(field.name)}
      className='mb-3'
      onChange={localOnChange}
      autoFocus={autoFocus}
      value={formattedValue}
    />
  );
};

const PhotoTaker = ({ isOpen, closeModal, photoCallback, format = ImageFormats.Png }) => {
  const webCamRef = React.useRef();

  const capture = () => {
    const imageSrc = webCamRef ? webCamRef.current.getScreenshot() : null;
    photoCallback(imageSrc);
    closeModal();
  };

  const videoConstraints = {
    width: PHOTO_WIDTH,
    height: PHOTO_HEIGHT,
    facingMode: 'environment'
  };

  return (
    <Modal isOpen={isOpen} autoFocus={true} toggle={closeModal}>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Webcam
          ref={webCamRef}
          videoConstraints={videoConstraints}
          pscreenshotformat={`image/${format}`}
          width={'100%'}
          height={'100%'}
        />
        <ButtonB style={{ width: '100%' }} type='button' onClick={capture}>
          <Icon name='camera' />
          Capturar
        </ButtonB>
      </div>
    </Modal>
  );
};

const PhotoDisplayer = ({ isOpen, closeModal, onDelete, img }) => {
  return (
    <Modal isOpen={isOpen} autoFocus={true} toggle={closeModal}>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Image wrapped src={img} onClick={closeModal} />
        <ButtonB style={{ width: '100%' }} type='button' onClick={onDelete}>
          <Icon name='trash' />
          Eliminar
        </ButtonB>
      </div>
    </Modal>
  );
};

const PhotoHolder = ({ onChange, constraints = {} }) => {
  const [images, setImages] = React.useState([]);
  const [isCameraOpen, setIsCameraOpen] = React.useState(false);
  const [isImageOpen, setIsImageOpen] = React.useState(false);
  const [selectedImg, setSelectedImg] = React.useState({ idx: -1, img: null });
  const { max = 0, format = ImageFormats.Png } = constraints;

  const switchModal = (modalFn, isOpen) => () => modalFn(isOpen);
  const onClickImage = (img) => () => {
    setSelectedImg(img);
    switchModal(setIsImageOpen, true)();
  };

  const onDeleteImage = (idx) => () => {
    if (idx !== -1) {
      const updatedImages = [...images];
      updatedImages.splice(idx, 1);
      setImages(updatedImages);
      setSelectedImg({ idx: -1, img: null });
      switchModal(setIsImageOpen, false)();
    }
  };

  const photoCallBack = (photo) => {
    const updatedImages = [...images, photo];
    setImages(updatedImages);
    onChange(updatedImages);
  };

  const onSelectStoredPhoto = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const img = await toBase64(event.target.files[0]).catch((e) => Error(e));
      if (img instanceof Error) {
        console.log('Error: ', img.message);
        return;
      }

      photoCallBack(img);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      {images.map((img, idx) => (
        <div
          key={`form_img_${idx}`}
          onClick={onClickImage({ img, idx })}
          style={{ marginLeft: '1em', marginTop: '1em' }}>
          <Image wrapped size='tiny' src={img} />
        </div>
      ))}
      {(max === 0 || max > images.length) && (
        <div style={{ margin: '1em 0 0 1em' }}>
          <ButtonB type='button' icon='camera' onClick={switchModal(setIsCameraOpen, true)} />
          <input
            id='upload-button'
            type='file'
            accept='image/*'
            style={{ display: 'none' }}
            name='storedImage'
            onChange={onSelectStoredPhoto}
          />
          <label htmlFor='upload-button' style={{ cursor: 'pointer' }}>
            <Icon name='upload' color='black' size='big' inverted style={{ marginLeft: '0.2em' }} />
          </label>
        </div>
      )}

      <PhotoDisplayer
        isOpen={isImageOpen}
        closeModal={switchModal(setIsImageOpen, false)}
        onDelete={onDeleteImage(selectedImg.idx)}
        img={selectedImg.img}
      />
      <PhotoTaker
        isOpen={isCameraOpen}
        closeModal={switchModal(setIsCameraOpen, false)}
        photoCallback={photoCallBack}
        format={format}
      />
    </div>
  );
};

export const GenericDropDownList = ({
  fieldName,
  onChange,
  data,
  valueField,
  textField,
  value,
  required,
  loading,
  dropDownOptions
}) => {
  const options = React.useMemo(
    () =>
      data && data.length > 0
        ? data.map((opt, idx) => ({
            key: `${fieldName}_${idx}`,
            value: opt[valueField],
            text: fusionFields(textField)(opt)
          }))
        : [],
    [data, valueField, textField, fieldName]
  );

  // NOTE: This make sure to set a safe value depending on the dropdown field 'multiple'
  const safeValue = dropDownOptions && dropDownOptions.multiple && !value ? [] : value;

  return (
    <Dropdown
      {...{
        className: 'mb-3',
        placeholder: `Seleccione ${fieldName}`,
        fluid: true,
        lazyLoad: true,
        search: true,
        selection: true,
        options,
        onChange,
        value: safeValue,
        clearable: !required,
        loading,
        disabled: loading,
        ...dropDownOptions
      }}
    />
  );
};

GenericDropDownList.propTypes = {
  fieldName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  valueField: PropTypes.string.isRequired,
  textField: PropTypes.string.isRequired,
  value: PropTypes.string,
  required: PropTypes.bool,
  loading: PropTypes.bool,
  dropDownOptions: PropTypes.object
};

// TODO: NOT Redraw all the form on any onChange.
// the onChange should redraw only its own component, not all the form
export class GenericForm extends Component {
  state = {};
  fetchedFields = null;
  referenceFields = [];
  allowedFields = [];

  constructor(props) {
    super(props);

    const { fields, isAdmin } = props;

    this.allowedFields = fields.filter((f) => !(f.onlyAdmin && !isAdmin));
    this.referenceFields = this.allowedFields.filter(
      (f) => f.type === FieldTypes.Reference || f.type === FieldTypes.MultiReference
    );
  }

  updateDependencyManager = (value, updateDependency, valueTransformation = (value) => value) => {
    // Make a function (setLoadings) to let updateDependency manage the loading state
    const setLoadings = (fields, isLoading) => {
      this.setState({ ...this.generateLoadingFields(fields, isLoading) });
    };

    // Make a function (updateFieldValue) to let updateDependency to update the state value
    const updateFieldValue = (fieldSelector, fetchedValue) => {
      this.setState({ [fieldSelector]: valueTransformation(fetchedValue) });
    };

    // Make a function (updateFetchedFields) to let updateDependency to makage the fetchedFieldsData
    const updateFetchedFields = (fetchedFieldsData) => {
      this.fetchedFields = { ...this.fetchedFields, ...fetchedFieldsData };
    };

    // updateDependency is the function given to trigger an update in another field
    // updateDependency gets:
    //
    // - The current value
    // - Function to get an entity field by the selector
    // - Function setLoadingsFunction
    // - Function updateFetchedFields
    updateDependency(
      value,
      this.getField(this.props.fields),
      setLoadings,
      updateFetchedFields,
      updateFieldValue,
      this.getFieldValue
    );
  };

  // Local callback to cast the semanticUI element's onChange returned data
  // into the required data for the given onChange callback
  onValueChange = (field, value) => {
    const { updateDependency } = field;
    this.setState({ [field.selector]: value });

    // If this field has an 'updateDependency'
    if (updateDependency) {
      this.updateDependencyManager(value, updateDependency);
    }
  };

  dropDownListOnChange = (field) => (event, data) => {
    this.onValueChange(field, data.value);
  };
  checkboxOnChange = (field) => (event, data) => {
    this.onValueChange(field, data.checked);
  };
  photoOnChange = (field) => (value) => {
    this.onValueChange(field, value);
  };
  datePickerOnChange = (field) => (value) => {
    this.onValueChange(field, value);
  };
  inputTextOnChange = (field) => (input) => {
    this.onValueChange(field, input.target.value);
  };

  componentDidMount = async () => {
    const { entityToEdit } = this.props;

    if (entityToEdit) {
      this.setState({
        ...this.allowedFields.reduce(
          (obj, field) => ({
            ...obj,
            [field.selector]: field.type !== FieldTypes.Password ? entityToEdit[field.selector] : ''
          }),
          {}
        )
      });
    }

    await this.fetchReferenceFields();
  };

  generateLoadingFields = (fields, isLoading) => {
    fields.reduce((acc, f) => {
      acc[`loading${capitalize(f.selector)}`] = isLoading;
      return acc;
    }, {});
  };

  getField = (fields) => (fieldSelector) => fields.find((f) => f.selector === fieldSelector);
  getFieldValue = (fieldSelector) => this.state[fieldSelector];
  isFieldLoading = (f) => this.state[`loading${capitalize(f.selector)}`];

  // TODO: Should we update 'loadingFields' aftear each fetch?
  fetchReferenceFields = async () => {
    this.setState({ ...this.generateLoadingFields(this.referenceFields, true) });

    // Fetching all the reference fields
    const fetchedFieldsArr = await Promise.all(
      this.referenceFields.map(async (f) => {
        // NOTE: You can set a manual data on the entity definition OR an API source
        const data = f.reference.data
          ? f.reference.data
          : await getEntity(f.reference.endpoint, {
              query: {
                ...f.reference.filter,
                ...(f.reference?.endpointQuery
                  ? f.reference.endpointQuery(this.props.entityToEdit)
                  : {})
              }
            });
        return { [f.selector]: { data } };
      })
    );

    const fetchedFields = fetchedFieldsArr.reduce((prev, curr) => ({ ...prev, ...curr }), {});
    this.fetchedFields = fetchedFields;
    this.setState({
      refreshToggle: !this.state.refreshToggle,
      ...this.generateLoadingFields(this.referenceFields, false)
    });
  };

  onSubmit = (allowedFields) => async (e) => {
    // Check required
    for (const f of allowedFields) {
      if (f.type !== FieldTypes.Boolean && f.required && !this.state[f.selector]) {
        e.preventDefault();
        warningPopAlert(`El campo ${f.name} es requerido`);
        return;
      }
    }

    const formFields = allowedFields.reduce(
      (acc, f) => ({ ...acc, [f.selector]: this.state[f.selector] }),
      {}
    );
    await this.props.onSubmit(e, formFields);

    if (this.props.refetchFieldsOnSubmit) await this.fetchReferenceFields();
  };

  getFetchedFieldData = (field) =>
    this.fetchedFields &&
    this.fetchedFields[field.selector] &&
    this.fetchedFields[field.selector].data &&
    this.fetchedFields[field.selector].data.length > 0
      ? this.fetchedFields[field.selector].data
      : [];

  render() {
    const { actionName } = this.props;
    let autoFocus = false;

    const { Text, Email, Rut, Password, Boolean, Reference, Date, Photo, MultiReference } =
      FieldTypes;

    const createField = (field, key) => (
      <Fragment key={`generic_form_${key}`}>
        <Label id={`label_${field.selector}`} for={field.selector}>
          {`${capitalize(field.name)}${field.required ? ' (*)' : ''}`}
        </Label>

        {/*TextField*/}
        {(field.type === Text || field.type === Email || field.type === Password) && (
          <FieldInputText
            {...{
              field,
              onChange: this.inputTextOnChange(field),
              value: this.state[field.selector] || '',
              autoFocus: !autoFocus ? (autoFocus = true && true) : false,
              disabled: field.disabled
            }}
          />
        )}

        {/*RutField*/}
        {field.type === Rut && (
          <FieldInputRut
            {...{
              field,
              onChange: this.inputTextOnChange(field),
              value: this.state[field.selector],
              autoFocus: !autoFocus ? (autoFocus = true && true) : false
            }}
          />
        )}

        {/*DatePicker*/}
        {field.type === Date && (
          <div style={{ display: 'block' }}>
            <DatePickerComponent
              {...{
                name: field.selector,
                onChange: this.datePickerOnChange(field),
                value: this.state[field.selector],
                format: field.format
              }}
            />
          </div>
        )}

        {/*CheckBox*/}
        {field.type === Boolean && (
          <div style={{ display: 'block' }}>
            <Checkbox
              onChange={this.checkboxOnChange(field)}
              checked={this.state[field.selector]}
              toggle
            />
          </div>
        )}

        {/*Reference Field (selector)*/}
        {(field.type === Reference || field.type === MultiReference) && (
          <GenericDropDownList
            {...{
              fieldName: field.name,
              data: this.getFetchedFieldData(field),
              valueField: field.reference.select,
              textField: field.reference.show,
              onChange: this.dropDownListOnChange(field),
              value: this.state[field.selector],
              required: field.required,
              loading: this.isFieldLoading(field),
              dropDownOptions: {
                multiple: field.type === MultiReference
              }
            }}
          />
        )}

        {/*PhotoField*/}
        {field.type === Photo && (
          <div style={{ display: 'block' }}>
            <PhotoHolder constraints={field.constraints} onChange={this.photoOnChange(field)} />
          </div>
        )}
      </Fragment>
    );

    // {position:number, groupNumber:number, children: [elements]}
    const addedGroup = [];

    return (
      <Form onSubmit={this.onSubmit(this.allowedFields)}>
        <FormGroup>
          {this.allowedFields.reduce((acc, field, key) => {
            if (!field.groupNumber) {
              acc.push(createField(field, key));
              return acc;
            }
            const preExistentGroup = addedGroup.find((ag) => ag.groupNumber === field.groupNumber);

            if (!preExistentGroup) {
              const children = createField(field, key);
              acc.push(<div id={`group_${field.groupNumber}`}>{children}</div>);
              addedGroup.push({
                groupNumber: field.groupNumber,
                childrens: [children],
                position: acc.length - 1
              });
            } else {
              const groupElement = acc[preExistentGroup.position];
              const children = createField(field, key);
              preExistentGroup.childrens.push(children);

              const updatedGroup = React.cloneElement(
                groupElement,
                {
                  style: {
                    display: 'flex',
                    gap: 10,
                    alignItems: 'center'
                  }
                },
                [...preExistentGroup.childrens]
              );

              acc[preExistentGroup.position] = updatedGroup;
            }

            return acc;
          }, [])}
          <Button color='dark' style={{ marginTop: '2rem' }} block>
            {actionName || `Agregar`}
          </Button>
        </FormGroup>
      </Form>
    );
  }
}

GenericForm.propTypes = {
  fields: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  refetchFieldsOnSubmit: PropTypes.bool,
  actionName: PropTypes.string,
  isAdmin: PropTypes.bool,
  entityToEdit: PropTypes.object
};
