import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthState } from '../reducers/types';
import { UserTypeValues } from '../types';
import { operadorLinks, publicLinks } from './App';

export const useUserAccess = (auth: AuthState) => {
  const history = useHistory();

  //const { trackingCode } = useParams<{ trackingCode: string | undefined }>();

  useEffect(() => {
    const { isLoading, isAuthenticated, user } = auth;

    // Admin can see all the app links
    if (isLoading || user?.userTypeName === UserTypeValues.Admin) {
      return;
    }

    // Anonimous users donesn't have links
    if (!isAuthenticated) {
      history.push(`/`);
      return;
    }

    // If is authenticated
    if (user?.userTypeName === UserTypeValues.Operador) {
      if (
        [...publicLinks, ...operadorLinks]
          .map(({ href }) => href)
          .includes(history.location.pathname)
      ) {
        return history.push(
          history.location.pathname === '/landing' ? '/mantenedores' : history.location
        );
      }
    }

    // If is any other user
    history.push('/');
  }, [auth, history]);
};
