import { LocationCd } from '../types';
import { FieldTypes, WebEntity } from './types';

const LocationCdEntity: WebEntity<LocationCd> = {
  name: 'locationCd',
  endpoint: 'locationCd',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'ID CD en Politicas de Inventario',
      selector: 'locationCdId',
      wrap: true
    },
    {
      name: 'Nombre CD en Politicas de Inventario',
      selector: 'locationCdName',
      wrap: true
    }
  ],

  fields: [
    {
      name: 'Nombre CD en Politicas de Inventario',
      selector: 'locationCdName',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: [
    {
      name: 'Nombre CD en Politicas de Inventario',
      selector: 'locationCdName',
      type: FieldTypes.Text,
      required: true
    }
  ],

  exportColumns: [
    {
      name: 'ID CD en Politicas de Inventario',
      selector: 'locationCdId',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Nombre CD en Politicas de Inventario',
      selector: 'locationCdName',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    }
  ]
};

export default LocationCdEntity;
