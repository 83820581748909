import { InconsistenciasMatrizProduccion } from '../types';
import { WebEntity } from './types';

const InconsistenciasMatrizProduccionEntity: WebEntity<InconsistenciasMatrizProduccion> = {
  name: 'inconsistenciasMatrizProduccion',
  endpoint: 'inconsistenciasMatrizProduccion',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Origen Matriz',
      selector: 'origenMatriz',
      wrap: true
    },
    {
      name: 'Plnt Codig',
      selector: 'plntCodig'
    },
    {
      name: 'Planta',
      selector: 'planta'
    },
    {
      name: 'Cd',
      selector: 'cd'
    },
    {
      name: 'Sku',
      selector: 'sku'
    },
    {
      name: 'Existe Sku En Mantenedor',
      selector: 'existeSkuEnMantenedor'
    },
    {
      name: 'Existe Cd En Mantenedor',
      selector: 'existeCdEnMantenedor'
    },
    {
      name: 'Existe Planta En Mantenedor',
      selector: 'existePlantaEnMantenedor'
    }
  ],

  fields: [],
  editableFields: [],
  filterFields: [],
  exportColumns: [
    {
      name: 'Origen Matriz',
      selector: 'origenMatriz',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Plnt Codig',
      selector: 'plntCodig',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Planta',
      selector: 'planta',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Cd',
      selector: 'cd',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Sku',
      selector: 'sku',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Existe Sku En Mantenedor',
      selector: 'existeSkuEnMantenedor',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Existe Cd En Mantenedor',
      selector: 'existeCdEnMantenedor',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Existe Planta En Mantenedor',
      selector: 'existePlantaEnMantenedor',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    }
  ]
};

export default InconsistenciasMatrizProduccionEntity;
