import React, { FunctionComponent, ReactNode, useState, CSSProperties } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonProps, Icon } from 'semantic-ui-react';

import {
  refreshReporteCdCl,
  refreshInconsistenciasReporteCantidad,
  refreshInconsistenciasRegistrosFaltantes,
  refreshInconsistenciasCategoria,
  refreshInconsistenciasCycleTimeProduccion,
  refreshInconsistenciasMatrizAcarreo,
  refreshInconsistenciasRutasIncompletas,
  refreshInconsistenciasMatrizProduccion,
  refreshInconsistenciasVariablesBaseCl,
  refreshInconsistenciasVariablesBaseCd
} from '../actions/middleActions';

type ButtonParams = {
  action: (params?: any) => Promise<void>;
  selected: any[];
};

type Props = {
  action: (params?: any) => Promise<void>;
  onClick: (buttonParams: ButtonParams) => Promise<void>;
  selected?: any[];
  btnProps: ButtonProps;
  children: ReactNode;
  style?: CSSProperties;
};

const SecondaryButton: FunctionComponent<Props> = ({
  action,
  onClick,
  selected = [],
  btnProps,
  children,
  style
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleOnClick = async () => {
    setLoading(true);
    await onClick({ action, selected });
    setLoading(false);
  };

  return (
    <>
      <Button
        {...{
          ...{
            key: 'secondary',
            style: { marginRight: '1em', ...(style || {}) },
            icon: false
          },
          ...btnProps,
          onClick: handleOnClick,
          loading
        }}>
        {children}
      </Button>
    </>
  );
};

const connectedSecondaryButton = (action: any) => connect(null, { action })(SecondaryButton);

export const RefreshReporteCdClButton = () => {
  const ConnectedBtn = connectedSecondaryButton(refreshReporteCdCl);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;
    await action();
  };

  return (
    <ConnectedBtn onClick={onClick} btnProps={{ basic: false, color: 'blue' }}>
      <Icon name='refresh' />
      Refrescar
    </ConnectedBtn>
  );
};

export const RefreshInconsistenciaReporteCantidadButton = () => {
  const ConnectedBtn = connectedSecondaryButton(refreshInconsistenciasReporteCantidad);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;
    await action();
  };

  return (
    <ConnectedBtn onClick={onClick} btnProps={{ basic: false, color: 'blue' }}>
      <Icon name='refresh' />
      Refrescar
    </ConnectedBtn>
  );
};

export const RefreshInconsistenciaRegistrosFaltantesButton = () => {
  const ConnectedBtn = connectedSecondaryButton(refreshInconsistenciasRegistrosFaltantes);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;
    await action();
  };

  return (
    <ConnectedBtn onClick={onClick} btnProps={{ basic: false, color: 'blue' }}>
      <Icon name='refresh' />
      Refrescar
    </ConnectedBtn>
  );
};

export const RefreshInconsistenciaCategoriaButton = () => {
  const ConnectedBtn = connectedSecondaryButton(refreshInconsistenciasCategoria);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;
    await action();
  };

  return (
    <ConnectedBtn onClick={onClick} btnProps={{ basic: false, color: 'blue' }}>
      <Icon name='refresh' />
      Refrescar
    </ConnectedBtn>
  );
};

export const RefreshInconsistenciaCycleTimeProduccionButton = () => {
  const ConnectedBtn = connectedSecondaryButton(refreshInconsistenciasCycleTimeProduccion);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;
    await action();
  };

  return (
    <ConnectedBtn onClick={onClick} btnProps={{ basic: false, color: 'blue' }}>
      <Icon name='refresh' />
      Refrescar
    </ConnectedBtn>
  );
};

export const RefreshInconsistenciaMatrizAcarreoButton = () => {
  const ConnectedBtn = connectedSecondaryButton(refreshInconsistenciasMatrizAcarreo);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;
    await action();
  };

  return (
    <ConnectedBtn onClick={onClick} btnProps={{ basic: false, color: 'blue' }}>
      <Icon name='refresh' />
      Refrescar
    </ConnectedBtn>
  );
};

export const RefreshInconsistenciaRutasIncompletasButton = () => {
  const ConnectedBtn = connectedSecondaryButton(refreshInconsistenciasRutasIncompletas);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;
    await action();
  };

  return (
    <ConnectedBtn onClick={onClick} btnProps={{ basic: false, color: 'blue' }}>
      <Icon name='refresh' />
      Refrescar
    </ConnectedBtn>
  );
};

export const RefreshInconsistenciaMatrizProduccionButton = () => {
  const ConnectedBtn = connectedSecondaryButton(refreshInconsistenciasMatrizProduccion);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;
    await action();
  };

  return (
    <ConnectedBtn onClick={onClick} btnProps={{ basic: false, color: 'blue' }}>
      <Icon name='refresh' />
      Refrescar
    </ConnectedBtn>
  );
};

export const RefreshInconsistenciaVariablesBaseClButton = () => {
  const ConnectedBtn = connectedSecondaryButton(refreshInconsistenciasVariablesBaseCl);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;
    await action();
  };

  return (
    <ConnectedBtn onClick={onClick} btnProps={{ basic: false, color: 'blue' }}>
      <Icon name='refresh' />
      Refrescar
    </ConnectedBtn>
  );
};

export const RefreshInconsistenciaVariablesBaseCdButton = () => {
  const ConnectedBtn = connectedSecondaryButton(refreshInconsistenciasVariablesBaseCd);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;
    await action();
  };

  return (
    <ConnectedBtn onClick={onClick} btnProps={{ basic: false, color: 'blue' }}>
      <Icon name='refresh' />
      Refrescar
    </ConnectedBtn>
  );
};
