import { CycleTimeProduccion } from '../types';
import { WebEntity } from './types';
import { specialFormatStringNumber } from '../utils/utils';

const CycleTimeProduccionEntity: WebEntity<CycleTimeProduccion> = {
  name: 'cycleTimeProduccion',
  endpoint: 'cycleTimeProduccion',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Categoria',
      selector: 'categoria'
    },
    {
      name: 'Planta',
      selector: 'planta'
    },
    {
      name: 'Sistema',
      selector: 'sistema'
    },
    {
      name: 'Sistema Dos',
      selector: 'sistemaDos'
    },
    {
      name: 'Sku',
      selector: 'sku'
    },
    {
      name: 'Abc',
      selector: 'abc'
    },
    {
      name: 'Cycle Time',
      selector: 'cycleTime',
      format: ({ cycleTime }) => specialFormatStringNumber(cycleTime, true, 0, 2)
    }
  ],

  fields: [],

  editableFields: [],
  exportColumns: [
    {
      name: 'Categoria',
      selector: 'categoria',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Planta',
      selector: 'planta',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Sistema',
      selector: 'sistema',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Sistema Dos',
      selector: 'sistemaDos',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Sku',
      selector: 'sku',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Abc',
      selector: 'abc',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Cycle Time',
      selector: 'cycleTime',
      format: 'numeric',
      decimalSeparator: ',',
      thousandSeparator: '.'
    }
  ]
};

// deafult 'key': {'name':'name','required':true,'export':true}
export const CY_TIME_PRD_COLUMNS = {
  categoria: 'Categoria',
  planta: 'Planta',
  sistema: 'Sistema',
  sistemaDos: 'Sistema Dos',
  sku: 'Sku',
  abc: 'Abc',
  cycleTime: 'Cycle Time'
};

export default CycleTimeProduccionEntity;
