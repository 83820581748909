import { LocationDictionaryCl } from '../types';
import { FieldTypes, WebEntity } from './types';

const LocationDictionaryClEntity: WebEntity<LocationDictionaryCl> = {
  name: 'locationDictionaryCl',
  endpoint: 'locationDictionaryCl',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'ID CL en Politicas de Inventario',
      selector: 'locationClId',
      wrap: true
    },
    {
      name: 'Nombre CL en Politicas de Inventario',
      selector: 'locationClName',
      wrap: true
    },
    {
      name: 'ID CL en el Origen',
      selector: 'locationDictionaryClId',
      wrap: true
    },
    {
      name: 'Nombre CL en el Origen',
      selector: 'locationDictionaryClNameSource',
      wrap: true
    }
  ],

  fields: [
    {
      name: 'Nombre CL en Politicas de Inventario',
      selector: 'locationClId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'locationClId',
        endpoint: 'locationCl',
        select: '_id',
        show: 'locationClName'
      }
    },
    {
      name: 'Nombre CL en el Origen',
      selector: 'locationDictionaryClNameSource',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: [
    {
      name: 'Nombre CL en Politicas de Inventario',
      selector: 'locationClId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'locationClId',
        endpoint: 'locationCl',
        select: '_id',
        show: 'locationClName'
      }
    },
    {
      name: 'Nombre CL en el Origen',
      selector: 'locationDictionaryClNameSource',
      type: FieldTypes.Text,
      required: true
    }
  ],

  exportColumns: [
    {
      name: 'ID CL en Politicas de Inventario',
      selector: 'locationClId',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Nombre CL en Politicas de Inventario',
      selector: 'locationClName',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'ID CL en el Origen',
      selector: 'locationDictionaryClId',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Nombre CL en el Origen',
      selector: 'locationDictionaryClNameSource',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    }
  ]
};

export default LocationDictionaryClEntity;
