import { Item } from '../types';
import { WebEntity } from './types';
import { specialFormatStringNumber } from '../utils/utils';

const ItemEntity: WebEntity<Item> = {
  name: 'item',
  endpoint: 'item',
  referenceColumn: 'itemIdCcu',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Sku',
      selector: 'itemIdCcu'
    },
    {
      name: 'Descripcion',
      selector: 'itemName',
      wrap: true
    },
    {
      name: 'Categoria',
      selector: 'itemCategory'
    },
    {
      name: 'Nueva Categoria',
      selector: 'itemCategoryNew'
    },
    {
      name: 'Formato',
      selector: 'itemFormat',
      wrap: true
    },
    {
      name: 'Empaque',
      selector: 'itemPacking',
      sortable: true
    },
    {
      name: 'Vida Util',
      selector: 'shelfLife',
      sortable: true,
      format: ({ shelfLife }) => specialFormatStringNumber(shelfLife, false, 0, 0)
    },
    {
      name: 'Factor Hl',
      selector: 'factorHl',
      sortable: true,
      format: ({ factorHl }) => specialFormatStringNumber(factorHl, true, 0, 2)
    }
  ],

  fields: [],

  editableFields: [],
  exportColumns: [
    {
      name: 'Sku',
      selector: 'itemIdCcu',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Descripcion',
      selector: 'itemName',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Categoria',
      selector: 'itemCategory',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Nueva Categoria',
      selector: 'itemCategoryNew',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Formato',
      selector: 'itemFormat',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Empaque',
      selector: 'itemPacking',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Vida Util',
      selector: 'shelfLife',
      format: 'numeric',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Factor Hl',
      selector: 'factorHl',
      format: 'numeric',
      decimalSeparator: ',',
      thousandSeparator: '.'
    }
  ]
};

// deafult 'key': {'name':'name','required':true,'export':true}
export const ITEM_COLUMNS = {
  itemIdCcu: 'Sku',
  itemName: 'Descripcion',
  itemCategory: 'Categoria',
  itemCategoryNew: 'Nueva Categoria',
  itemFormat: 'Formato',
  itemPacking: 'Empaque',
  shelfLife: 'Vida Util',
  factorHl: 'Factor Hl'
};

export default ItemEntity;
