import { InconsistenciasVariablesBaseCd } from '../types';
import { WebEntity } from './types';

const InconsistenciasVariablesBaseCdEntity: WebEntity<InconsistenciasVariablesBaseCd> = {
  name: 'inconsistenciasVariablesBaseCd',
  endpoint: 'inconsistenciasVariablesBaseCd',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Sku',
      selector: 'sku'
    },
    {
      name: 'Id Cd',
      selector: 'idCd'
    },
    {
      name: 'Descripcion',
      selector: 'descripcion',
      wrap: true
    },
    {
      name: 'Categoria',
      selector: 'categoria',
      wrap: true
    },
    {
      name: 'Descr Depo',
      selector: 'descrDepo',
      wrap: true
    },
    {
      name: 'Existe En Mantenedor Sku',
      selector: 'existeEnMantenedorSku',
      wrap: true
    },
    {
      name: 'Posee Lead Time',
      selector: 'poseeLeadTime'
    },
    {
      name: 'Existe En Mantenedor Locaciones',
      selector: 'existeEnMantenedorLocaciones'
    },
    {
      name: 'Posee Cycle Time Distribucion',
      selector: 'poseeCycleTimeDistribucion'
    },
    {
      name: 'Posee Nivel De Servicio',
      selector: 'poseeNivelDeServicio'
    }
  ],

  fields: [],
  editableFields: [],
  filterFields: [],
  exportColumns: [
    {
      name: 'Sku',
      selector: 'sku',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Id Cd',
      selector: 'idCd',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Descripcion',
      selector: 'descripcion',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Categoria',
      selector: 'categoria',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Descr Depo',
      selector: 'descrDepo',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Existe En Mantenedor Sku',
      selector: 'existeEnMantenedorSku',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Posee Lead Time',
      selector: 'poseeLeadTime',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Existe En Mantenedor Locaciones',
      selector: 'existeEnMantenedorLocaciones',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Posee Cycle Time Distribucion',
      selector: 'poseeCycleTimeDistribucion',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Posee Nivel De Servicio',
      selector: 'poseeNivelDeServicio',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    }
  ]
};

export default InconsistenciasVariablesBaseCdEntity;
