import axios from 'axios';
import * as E from '../entities';
import { WebEntityName } from '../entities/types';
import { AppDispatch, AppGetState } from '../store';
import { tokenConfig } from '../utils/header';
import { REFRESH_ENTITY } from '../reducers/types';
import { getEntitiesAction } from './entitiesActions';
import { handleError, warningPopAlert } from '../components/PopAlert';
import {
  SimpleObject,
  Item,
  MatrizProduccion,
  MatrizAcarreo,
  CycleTimeDistribucion,
  CycleTimeProduccion,
  NivelServicio,
  OrderCreatedValues
} from '../types';

export const cleanSelectedEntities =
  (entityName: WebEntityName) => async (dispatch: AppDispatch) => {
    dispatch({
      type: REFRESH_ENTITY,
      payload: null,
      entityName: entityName
    });
  };

// REFRESHERS

const refreshEntity =
  (entity: SimpleObject, serverSidePagination?: boolean, query?: SimpleObject) =>
  async (dispatch: AppDispatch, getState: AppGetState) =>
    await getEntitiesAction(
      entity.name as WebEntityName,
      entity.endpoint,
      query ? { query } : {}
    )(serverSidePagination ? 1 : undefined)(dispatch, getState);

/**
 * Add Example
 *
 * @param inventario: Array<GestionInventario>
 */
// export const ingresarInventario =
//   (inventario: Array<GestionInventario>, date: Date, zona: string) =>
//   async (dispatch: AppDispatch, getState: AppGetState) => {
//     try {
//       const res = await axios.post(
//         `/api/inventario`,
//         { data: inventario, fechaDocumento: cleanTextDate(date), zona },
//         tokenConfig(getState)
//       );
//       await refreshDocumento(dispatch, getState);
//       return res;
//     } catch (error) {
//       handleError({ error, entityName: E.GestionInventarioEntity.name, dispatch });
//     }
//   };

export const refreshReporteCdCl = () => async (dispatch: AppDispatch, getState: AppGetState) => {
  try {
    await axios.post(`/api/politicasInventarioCdCl/refresh`, {}, tokenConfig(getState));
    await refreshEntity(E.PoliticasInventarioCdClEntity, true, {
      orderCreated: OrderCreatedValues.LastUpdated
    })(dispatch, getState);
  } catch (error) {
    handleError({ error, entityName: E.PoliticasInventarioCdClEntity.name, dispatch });
  }
};

export const refreshInconsistenciasReporteCantidad =
  () => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await axios.post(`/api/inconsistenciasReporteCantidad/refresh`, {}, tokenConfig(getState));
      await refreshEntity(E.InconsistenciasReporteCantidadEntity)(dispatch, getState);
    } catch (error) {
      handleError({ error, entityName: E.InconsistenciasReporteCantidadEntity.name, dispatch });
    }
  };

export const refreshInconsistenciasRegistrosFaltantes =
  () => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await axios.post(`/api/inconsistenciasRegistrosFaltantes/refresh`, {}, tokenConfig(getState));
      await refreshEntity(E.InconsistenciasRegistrosFaltantesEntity)(dispatch, getState);
    } catch (error) {
      handleError({ error, entityName: E.InconsistenciasRegistrosFaltantesEntity.name, dispatch });
    }
  };

export const refreshInconsistenciasCategoria =
  () => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await axios.post(`/api/inconsistenciasCategoria/refresh`, {}, tokenConfig(getState));
      await refreshEntity(E.InconsistenciasCategoriaEntity)(dispatch, getState);
    } catch (error) {
      handleError({ error, entityName: E.InconsistenciasCategoriaEntity.name, dispatch });
    }
  };

export const refreshInconsistenciasCycleTimeProduccion =
  () => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await axios.post(
        `/api/inconsistenciasCycleTimeProduccion/refresh`,
        {},
        tokenConfig(getState)
      );
      await refreshEntity(E.InconsistenciasCycleTimeProduccionEntity)(dispatch, getState);
    } catch (error) {
      handleError({ error, entityName: E.InconsistenciasCycleTimeProduccionEntity.name, dispatch });
    }
  };

export const refreshInconsistenciasMatrizAcarreo =
  () => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await axios.post(`/api/inconsistenciasMatrizAcarreo/refresh`, {}, tokenConfig(getState));
      await refreshEntity(E.InconsistenciasMatrizAcarreoEntity)(dispatch, getState);
    } catch (error) {
      handleError({ error, entityName: E.InconsistenciasMatrizAcarreoEntity.name, dispatch });
    }
  };

export const refreshInconsistenciasRutasIncompletas =
  () => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await axios.post(`/api/inconsistenciasRutasIncompletas/refresh`, {}, tokenConfig(getState));
      await refreshEntity(E.InconsistenciasRutasIncompletasEntity)(dispatch, getState);
    } catch (error) {
      handleError({ error, entityName: E.InconsistenciasRutasIncompletasEntity.name, dispatch });
    }
  };

export const refreshInconsistenciasMatrizProduccion =
  () => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await axios.post(`/api/inconsistenciasMatrizProduccion/refresh`, {}, tokenConfig(getState));
      await refreshEntity(E.InconsistenciasMatrizProduccionEntity)(dispatch, getState);
    } catch (error) {
      handleError({ error, entityName: E.InconsistenciasMatrizProduccionEntity.name, dispatch });
    }
  };

export const refreshInconsistenciasVariablesBaseCl =
  () => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await axios.post(`/api/inconsistenciasVariablesBaseCl/refresh`, {}, tokenConfig(getState));
      await refreshEntity(E.InconsistenciasVariablesBaseClEntity)(dispatch, getState);
    } catch (error) {
      handleError({ error, entityName: E.InconsistenciasVariablesBaseClEntity.name, dispatch });
    }
  };

export const refreshInconsistenciasVariablesBaseCd =
  () => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await axios.post(`/api/inconsistenciasVariablesBaseCd/refresh`, {}, tokenConfig(getState));
      await refreshEntity(E.InconsistenciasVariablesBaseCdEntity)(dispatch, getState);
    } catch (error) {
      handleError({ error, entityName: E.InconsistenciasVariablesBaseCdEntity.name, dispatch });
    }
  };

export const itemMassiveLoad =
  (itemData: Array<Item>) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await axios.post(`/api/item/merge`, itemData, tokenConfig(getState));
      await refreshEntity(E.ItemEntity)(dispatch, getState);
    } catch (error) {
      handleError({ error, entityName: E.ItemEntity.name, dispatch });
    }
  };

export const matrizProduccionMassiveLoad =
  (matrizProduccionData: Array<MatrizProduccion>) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await axios.post(
        `/api/matrizProduccion/massiveLoad`,
        matrizProduccionData,
        tokenConfig(getState)
      );
      await refreshEntity(E.MatrizProduccionEntity)(dispatch, getState);
    } catch (error) {
      handleError({ error, entityName: E.MatrizProduccionEntity.name, dispatch });
    }
  };

export const matrizAcarreoMassiveLoad =
  (matrizAcarreoData: Array<MatrizAcarreo>) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(
        `/api/matrizAcarreo/merge`,
        matrizAcarreoData,
        tokenConfig(getState)
      );
      await refreshEntity(E.MatrizAcarreoEntity)(dispatch, getState);

      if (res.status !== 200) {
        return;
      }

      const title = `${res.data.quantityInsert} items registrados. ${res.data.errorList.length} items con error.`;
      const message = '';
      const details = `${res.data.errorList.join('\n') + '\n'}`;
      warningPopAlert(title, message, details);
    } catch (error) {
      handleError({ error, entityName: E.MatrizAcarreoEntity.name, dispatch });
    }
  };

export const cycleTimeDistribucionMassiveLoad =
  (cycleTimeDistribucionData: Array<CycleTimeDistribucion>) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await axios.post(
        `/api/cycleTimeDistribucion/massiveLoad`,
        cycleTimeDistribucionData,
        tokenConfig(getState)
      );
      await refreshEntity(E.CycleTimeDistribucionEntity)(dispatch, getState);
    } catch (error) {
      handleError({ error, entityName: E.CycleTimeDistribucionEntity.name, dispatch });
    }
  };

export const cycleTimeProduccionMassiveLoad =
  (cycleTimeProduccionData: Array<CycleTimeProduccion>) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await axios.post(
        `/api/cycleTimeProduccion/massiveLoad`,
        cycleTimeProduccionData,
        tokenConfig(getState)
      );
      await refreshEntity(E.CycleTimeProduccionEntity)(dispatch, getState);
    } catch (error) {
      handleError({ error, entityName: E.CycleTimeProduccionEntity.name, dispatch });
    }
  };

export const nivelServicioMassiveLoad =
  (nivelServicioData: Array<NivelServicio>) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await axios.post(`/api/nivelServicio/massiveLoad`, nivelServicioData, tokenConfig(getState));
      await refreshEntity(E.NivelServicioEntity)(dispatch, getState);
    } catch (error) {
      handleError({ error, entityName: E.NivelServicioEntity.name, dispatch });
    }
  };
