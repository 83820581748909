import { Sistema } from '../types';
import { WebEntity } from './types';

const SistemaEntity: WebEntity<Sistema> = {
  name: 'sistema',
  endpoint: 'sistema',
  referenceColumn: 'sistemaId',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Sistema Id',
      selector: 'sistemaId',
      sortable: true
    },
    {
      name: 'Sistema Descripcion',
      selector: 'sistemaDescripcion',
      sortable: true
    }
  ],

  fields: [],

  editableFields: []
};

export default SistemaEntity;
