import React from 'react';
import {
  InconsistenciaCategoria,
  InconsistenciaCycleTimeProduccion,
  InconsistenciaMatrizAcarreo,
  InconsistenciaRutasIncompletas,
  InconsistenciaMatrizProduccion,
  InconsistenciaVariablesBaseCl,
  InconsistenciaVariablesBaseCd,
  InconsistenciaReporteCantidad,
  InconsistenciaRegistrosFaltantes
} from '../components/GeneratedComponents';
import {
  RefreshInconsistenciaReporteCantidadButton,
  RefreshInconsistenciaRegistrosFaltantesButton,
  RefreshInconsistenciaCategoriaButton,
  RefreshInconsistenciaCycleTimeProduccionButton,
  RefreshInconsistenciaMatrizAcarreoButton,
  RefreshInconsistenciaRutasIncompletasButton,
  RefreshInconsistenciaMatrizProduccionButton,
  RefreshInconsistenciaVariablesBaseClButton,
  RefreshInconsistenciaVariablesBaseCdButton
} from '../components/SecondaryButton';

export const InconsistenciaReporteCantidadComponent = () => (
  <div>
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 0 }}>
      <RefreshInconsistenciaReporteCantidadButton />
    </div>
    <InconsistenciaReporteCantidad />
  </div>
);

export const InconsistenciaRegistrosFaltantesComponent = () => (
  <div>
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 0 }}>
      <RefreshInconsistenciaRegistrosFaltantesButton />
    </div>
    <InconsistenciaRegistrosFaltantes />
  </div>
);

export const InconsistenciaCategoriaComponent = () => (
  <div>
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 0 }}>
      <RefreshInconsistenciaCategoriaButton />
    </div>
    <InconsistenciaCategoria />
  </div>
);

export const InconsistenciaCycleTimeProduccionComponent = () => (
  <div>
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 0 }}>
      <RefreshInconsistenciaCycleTimeProduccionButton />
    </div>
    <InconsistenciaCycleTimeProduccion />
  </div>
);

export const InconsistenciaMatrizAcarreoComponent = () => (
  <div>
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 0 }}>
      <RefreshInconsistenciaMatrizAcarreoButton />
    </div>
    <InconsistenciaMatrizAcarreo />
  </div>
);

export const InconsistenciaRutasIncompletasComponenet = () => (
  <div>
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 0 }}>
      <RefreshInconsistenciaRutasIncompletasButton />
    </div>
    <InconsistenciaRutasIncompletas />
  </div>
);

export const InconsistenciaMatrizProduccionComponent = () => (
  <div>
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 0 }}>
      <RefreshInconsistenciaMatrizProduccionButton />
    </div>
    <InconsistenciaMatrizProduccion />
  </div>
);

export const InconsistenciaVariablesBaseClComponent = () => (
  <div>
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 0 }}>
      <RefreshInconsistenciaVariablesBaseClButton />
    </div>
    <InconsistenciaVariablesBaseCl />
  </div>
);

export const InconsistenciaVariablesBaseCdComponent = () => (
  <div>
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 0 }}>
      <RefreshInconsistenciaVariablesBaseCdButton />
    </div>
    <InconsistenciaVariablesBaseCd />
  </div>
);
