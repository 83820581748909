import { InconsistenciasRutasIncompletas } from '../types';
import { WebEntity } from './types';

const InconsistenciasRutasIncompletasEntity: WebEntity<InconsistenciasRutasIncompletas> = {
  name: 'inconsistenciasRutasIncompletas',
  endpoint: 'inconsistenciasRutasIncompletas',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Cod Centro Origen',
      selector: 'codCentroOrigen'
    },
    {
      name: 'Descr Sap Origen',
      selector: 'descrSapOrigen',
      wrap: true
    },
    {
      name: 'Descr Depo Origen',
      selector: 'descrDepoOrigen'
    },
    {
      name: 'Cod Centro Destino',
      selector: 'codCentroDestino'
    },
    {
      name: 'Descr Sap Destino',
      selector: 'descrSapDestino',
      wrap: true
    },
    {
      name: 'Descr Depo Destino',
      selector: 'descrDepoDestino'
    }
  ],

  fields: [],
  editableFields: [],
  filterFields: [],
  exportColumns: [
    {
      name: 'Cod Centro Origen',
      selector: 'codCentroOrigen',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Descr Sap Origen',
      selector: 'descrSapOrigen',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Descr Depo Origen',
      selector: 'descrDepoOrigen',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Cod Centro Destino',
      selector: 'codCentroDestino',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Descr Sap Destino',
      selector: 'descrSapDestino',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Descr Depo Destino',
      selector: 'descrDepoDestino',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    }
  ]
};

export default InconsistenciasRutasIncompletasEntity;
