import { CycleTimeDistribucion } from '../types';
import { WebEntity } from './types';
import { specialFormatStringNumber } from '../utils/utils';

const CycleTimeDistribucionEntity: WebEntity<CycleTimeDistribucion> = {
  name: 'cycleTimeDistribucion',
  endpoint: 'cycleTimeDistribucion',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Cd',
      selector: 'cd'
    },
    {
      name: 'Descr Grupo Prod',
      selector: 'descrGrupoProd',
      wrap: true
    },
    {
      name: 'Tipo',
      selector: 'tipo'
    },
    {
      name: 'Cycle Time',
      selector: 'cycleTime',
      format: ({ cycleTime }) => specialFormatStringNumber(cycleTime, true, 0, 2)
    },
    {
      name: 'Ruta',
      selector: 'ruta',
      wrap: true
    },
    {
      name: 'Lead Time Avg',
      selector: 'leadTimeAvg',
      format: ({ leadTimeAvg }) => specialFormatStringNumber(leadTimeAvg, false, 0, 0)
    },
    {
      name: 'Dias Lab',
      selector: 'diasLab',
      format: ({ diasLab }) => specialFormatStringNumber(diasLab, false, 0, 0)
    }
  ],

  fields: [],

  editableFields: [],

  exportColumns: [
    {
      name: 'Cd',
      selector: 'cd',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Descr Grupo Prod',
      selector: 'descrGrupoProd',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Tipo',
      selector: 'tipo',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Cycle Time',
      selector: 'cycleTime',
      format: 'numeric',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Ruta',
      selector: 'ruta',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Lead Time Avg',
      selector: 'leadTimeAvg',
      format: 'numeric',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Dias Lab',
      selector: 'diasLab',
      format: 'numeric',
      decimalSeparator: ',',
      thousandSeparator: '.'
    }
  ]
};

// deafult 'key': {'name':'name','required':true,'export':true}
export const CY_TIME_DIS_COLUMNS = {
  cd: 'Cd',
  descrGrupoProd: 'Descr Grupo Prod',
  tipo: 'Tipo',
  cycleTime: 'Cycle Time',
  ruta: 'Ruta',
  leadTimeAvg: 'Lead Time Avg',
  diasLab: 'Dias Lab'
};

export default CycleTimeDistribucionEntity;
