import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../img/ccu_logo.png';
import React, { FunctionComponent, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { loadUser } from '../actions/authActions';
import '../css/AppHome.css';
import Pages, { Mantenedores } from '../pages';
import AppNavbar from './AppNavbar';
import { AppLink } from './types';
import { AppState } from '../store';
import { AuthState } from '../reducers/types';
import Login from './auth/Login';
import GenericContainerSidebar from './generics/GenericContainerSidebar/GenericContainerSidebar';
import Logout from './auth/Logout';
import {
  InconsistenciaCategoriaComponent,
  InconsistenciaCycleTimeProduccionComponent,
  //InconsistenciaMatrizAcarreoComponent,
  InconsistenciaMatrizProduccionComponent,
  InconsistenciaRutasIncompletasComponenet,
  //InconsistenciaVariablesBaseClComponent,
  //InconsistenciaVariablesBaseCdComponent,
  InconsistenciaReporteCantidadComponent,
  InconsistenciaRegistrosFaltantesComponent
} from '../pages/InconsistenciasPage';
import PoliticaCdClPage from '../pages/PoliticaCdClPage';

export const hiddenLinks: AppLink[] = [
  {
    href: '/landing',
    name: 'Landing',
    component: Pages.LandingPage
  },
  {
    href: '/',
    name: 'Inicio',
    component: Pages.AnonimousPage
  }
];

export const publicLinks: AppLink[] = [];

const adminLinks: AppLink[] = [
  {
    href: '/mantenedores/usuario',
    name: 'Usuario',
    component: Mantenedores.UsuarioCRUDMaintainer
  },
  {
    href: '/mantenedores/tipo-usuario',
    name: 'Tipo de Usuario',
    component: Mantenedores.TipoUsuarioCRUDMaintainer
  },
  {
    href: '/mantenedores/sku',
    name: 'SKU',
    component: Mantenedores.ItemMaintainer
  },
  {
    href: '/mantenedores/matriz-acarreo',
    name: 'Matriz Acarreo',
    component: Mantenedores.matrizAcarreoManteiner // FilereaderComponent
  },
  {
    href: '/mantenedores/matriz-produccion',
    name: 'Matriz Producción',
    component: Mantenedores.matrizProduccionManteiner // FilereaderComponent
  },
  {
    href: '/mantenedores/cycle-time-produccion',
    name: 'Cycle Time Producción',
    component: Mantenedores.PrecycleTimeProduccionManteiner // FilereaderComponent
  },
  {
    href: '/mantenedores/cycle-time-distribucion',
    name: 'Cycle Time Distribución',
    component: Mantenedores.PrecycleTimeDistribucionManteiner // FilereaderComponent
  },
  {
    href: '/mantenedores/nivel-servicio',
    name: 'Nivel Servicio',
    component: Mantenedores.PrenivelServicioManteiner // FilereaderComponent
  },
  {
    href: '/mantenedores/constantes',
    name: 'Constantes',
    component: Mantenedores.ConstantesCRUDManteiner
  },
  {
    href: '/mantenedores/locaciones-cd',
    name: 'Locaciones CD',
    component: Mantenedores.LocationCdCRUDManteiner
  },
  {
    href: '/mantenedores/diccionario-locaciones-cd',
    name: 'Diccionario Locaciones CD',
    component: Mantenedores.LocationDictionaryCdCRUDManteiner
  },
  {
    href: '/mantenedores/locaciones-cl',
    name: 'Locaciones CL',
    component: Mantenedores.LocationClCRUDManteiner
  },
  {
    href: '/mantenedores/diccionario-locaciones-cl',
    name: 'Diccionario Locaciones CL',
    component: Mantenedores.LocationDictionaryClCRUDManteiner
  }
];

export const operadorLinks: AppLink[] = [
  {
    href: '/inconsistencias/reporte-cantidad',
    name: 'Inconsistencias',
    component: InconsistenciaReporteCantidadComponent
  },
  {
    href: '/inconsistencias/registros-faltantes',
    name: 'Inconsistencias',
    component: InconsistenciaRegistrosFaltantesComponent
  },
  {
    href: '/inconsistencias/categoria',
    name: 'Inconsistencias',
    component: InconsistenciaCategoriaComponent
  },
  {
    href: '/inconsistencias/cycle-time-produccion',
    name: 'Inconsistencias',
    component: InconsistenciaCycleTimeProduccionComponent
  },
  /*{
    href: '/inconsistencias/matriz-acarreo',
    name: 'Inconsistencias',
    component: InconsistenciaMatrizAcarreoComponent
  },*/
  {
    href: '/inconsistencias/rutas-incompletas',
    name: 'Inconsistencias',
    component: InconsistenciaRutasIncompletasComponenet
  },
  {
    href: '/inconsistencias/matriz-produccion',
    name: 'Inconsistencias',
    component: InconsistenciaMatrizProduccionComponent
  },
  /*{
    href: '/inconsistencias/variables-base-cl',
    name: 'Inconsistencias',
    component: InconsistenciaVariablesBaseClComponent
  },
  {
    href: '/inconsistencias/variables-base-cd',
    name: 'Inconsistencias',
    component: InconsistenciaVariablesBaseCdComponent
  },*/
  {
    href: '/politicas/reportes-cd-cl',
    name: 'Politicas inventario',
    component: PoliticaCdClPage
  }
];

const autoRoutes = () =>
  [...hiddenLinks, ...publicLinks, ...adminLinks, ...operadorLinks].map(
    ({ component, href }, k) => (
      <Route key={`main_menu_${k}`} exact path={href} component={component} />
    )
  );

type Props = {
  loadUser: () => Promise<void>;
  auth: AuthState;
};

const App: FunctionComponent<Props> = ({ auth, loadUser }) => {
  useEffect(() => {
    loadUser();
  }, [loadUser]);

  return (
    <div className='App'>
      <Helmet>
        <meta name={'X-App-Version'} content={`${process.env.REACT_APP_VERSION}`} />
      </Helmet>
      <Router>
        {auth.isAuthenticated ? (
          <GenericContainerSidebar
            img={logo}
            AppNavbar={<AppNavbar />}
            linkTopNav={'/politicas/reportes-cd-cl'}
            logout={<Logout />}
            user={auth.user?.userUsername}>
            <Switch>
              {autoRoutes()}
              <Route path='/404' component={Pages.NotFoundPage} />
              <Redirect to='/404' />
            </Switch>
          </GenericContainerSidebar>
        ) : (
          <Login />
        )}
      </Router>
    </div>
  );
};

export default connect(({ auth }: AppState) => ({ auth }), { loadUser })(App);
