import { InconsistenciasReporteCantidad } from '../types';
import { WebEntity } from './types';
import { specialFormatStringNumber } from '../utils/utils';

const InconsistenciasReporteCantidadEntity: WebEntity<InconsistenciasReporteCantidad> = {
  name: 'inconsistenciasReporteCantidad',
  endpoint: 'inconsistenciasReporteCantidad',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Cd Cl',
      selector: 'locationCdClName',
      wrap: true
    },
    {
      name: 'Nombre Categoria',
      selector: 'itemCategoryName',
      wrap: true
    },
    {
      name: 'Forecast',
      selector: 'forecast',
      format: ({ forecast }) => specialFormatStringNumber(forecast, true, 0, 0),
      wrap: true
    },
    {
      name: 'Venta',
      selector: 'venta',
      format: ({ venta }) => specialFormatStringNumber(venta, true, 0, 0),
      wrap: true
    },
    {
      name: 'Forecast Venta',
      selector: 'forecastVenta',
      format: ({ forecastVenta }) => specialFormatStringNumber(forecastVenta, true, 0, 0),
      wrap: true
    },
    {
      name: 'Variables Base Cd Stg',
      selector: 'variablesBaseCdStg',
      format: ({ variablesBaseCdStg }) => specialFormatStringNumber(variablesBaseCdStg, true, 0, 0),
      wrap: true
    },
    {
      name: 'Variables Base Cd',
      selector: 'variablesBaseCd',
      format: ({ variablesBaseCd }) => specialFormatStringNumber(variablesBaseCd, true, 0, 0),
      wrap: true
    },
    {
      name: 'Politicas Inventario Cd',
      selector: 'politicasInventarioCd',
      format: ({ politicasInventarioCd }) =>
        specialFormatStringNumber(politicasInventarioCd, true, 0, 0),
      wrap: true
    },
    {
      name: 'Forecast Future Stg Cd',
      selector: 'forecastFutureStgCd',
      format: ({ forecastFutureStgCd }) =>
        specialFormatStringNumber(forecastFutureStgCd, true, 0, 0),
      wrap: true
    },
    {
      name: 'Demand Average Cd',
      selector: 'demandAverageCd',
      format: ({ demandAverageCd }) => specialFormatStringNumber(demandAverageCd, true, 0, 0),
      wrap: true
    },
    {
      name: 'Variables Base Cl Stg',
      selector: 'variablesBaseClStg',
      format: ({ variablesBaseClStg }) => specialFormatStringNumber(variablesBaseClStg, true, 0, 0),
      wrap: true
    },
    {
      name: 'Variables Base Cl',
      selector: 'variablesBaseCl',
      format: ({ variablesBaseCl }) => specialFormatStringNumber(variablesBaseCl, true, 0, 0),
      wrap: true
    },
    {
      name: 'Politicas Inventario Cl',
      selector: 'politicasInventarioCl',
      format: ({ politicasInventarioCl }) =>
        specialFormatStringNumber(politicasInventarioCl, true, 0, 0),
      wrap: true
    },
    {
      name: 'Forecast Future Stg Cl',
      selector: 'forecastFutureStgCl',
      format: ({ forecastFutureStgCl }) =>
        specialFormatStringNumber(forecastFutureStgCl, true, 0, 0),
      wrap: true
    },
    {
      name: 'Demand Average Cl',
      selector: 'demandAverageCl',
      format: ({ demandAverageCl }) => specialFormatStringNumber(demandAverageCl, true, 0, 0),
      wrap: true
    },
    {
      name: 'Tipo Locacion',
      selector: 'locationType',
      wrap: true
    }
  ],

  fields: [],
  editableFields: [],
  filterFields: [],
  exportColumns: [
    {
      name: 'Cd Cl',
      selector: 'locationCdClName',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Nombre Categoria',
      selector: 'itemCategoryName',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Forecast',
      selector: 'forecast',
      format: 'numeric',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Venta',
      selector: 'venta',
      format: 'numeric',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Forecast Venta',
      selector: 'forecastVenta',
      format: 'numeric',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Variables Base Cd Stg',
      selector: 'variablesBaseCdStg',
      format: 'numeric',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Variables Base Cd',
      selector: 'variablesBaseCd',
      format: 'numeric',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Politicas Inventario Cd',
      selector: 'politicasInventarioCd',
      format: 'numeric',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Forecast Future Stg Cd',
      selector: 'forecastFutureStgCd',
      format: 'numeric',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Demand Average Cd',
      selector: 'demandAverageCd',
      format: 'numeric',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Variables Base Cl Stg',
      selector: 'variablesBaseClStg',
      format: 'numeric',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Variables Base Cl',
      selector: 'variablesBaseCl',
      format: 'numeric',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Politicas Inventario Cl',
      selector: 'politicasInventarioCl',
      format: 'numeric',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Forecast Future Stg Cl',
      selector: 'forecastFutureStgCl',
      format: 'numeric',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Demand Average Cl',
      selector: 'demandAverageCl',
      format: 'numeric',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: 'Tipo Locacion',
      selector: 'locationType',
      format: 'text',
      decimalSeparator: ',',
      thousandSeparator: '.'
    }
  ]
};

export default InconsistenciasReporteCantidadEntity;
